import React from "react";
import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { TreeProps } from "antd";
import { onError } from "src/common/onError";
import { flattenItems } from "src/common/flattenItems";
import { TableStore } from "src/components/tables/TableStore";
import { ZChildAttribute } from "./ZChildAttribute";
import { makeChildAttsTree } from "./utils/makeChildAttsTree";
import { copyEntity, loadChildAttributes } from "./apiEntityCopy";
import { ChildAttsTreeItem } from "./EntityCopyButton";
import { makeParentAttsMap } from "./utils/makeParentAttsMap";
import { ZEntityFilters, ZEntityRow } from "../types";

export class EntityCopyButtonStore {
  constructor(
    public readonly objId: number,
    public readonly tableStore: TableStore<ZEntityRow, ZEntityFilters>,
  ) {
    makeAutoObservable(this);
  }

  data: RemoteData<ZChildAttribute[]> = { status: "none" };

  setData(newData: RemoteData<ZChildAttribute[]>) {
    this.data = newData;
  }

  get treeData(): ChildAttsTreeItem[] | null {
    return this.data.status === "ready"
      ? makeChildAttsTree(this.data.result)
      : null;
  }

  async init() {
    try {
      this.setData({ status: "wait" });
      const result = await loadChildAttributes(this.objId, { translate: true });
      this.setData({ status: "ready", result });
      this.setExpanded(this.defaultExpanded ?? []);
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  isModalOpen: boolean = false;

  setIsModalOpen(flag: boolean) {
    this.isModalOpen = flag;
  }

  loading: boolean = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  async save(values?: number[]) {
    try {
      const entityId = this.tableStore.selected[0]?.id;
      if (!entityId) return;
      this.setLoading(true);
      await copyEntity(entityId, values);
      this.tableStore.reload();
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
      this.setIsModalOpen(false);
    }
  }

  // check

  /* /  Задача требует чтобы выбирая родителя - не выбирались все его дети,
  но при этом выбирая ребенка - выбирались все родители, 
  поэтому пришлось написать кастомную логику/ */

  checkedItems = new Set<React.Key>();

  setCheckedItems(items: React.Key[]) {
    this.checkedItems = new Set(items);
  }

  get checkedKeys(): React.Key[] {
    return Array.from(this.checkedItems);
  }

  get defaultChecked(): React.Key[] | null {
    const { data } = this;
    return data.status === "ready"
      ? flattenItems(data.result, "linkedAttributes")?.map(
          ({ attributeId }) => attributeId,
        ) ?? null
      : null;
  }

  get parentAtstMap(): Record<string, React.Key[]> | null {
    return this.treeData ? makeParentAttsMap(this.treeData) : null;
  }

  onCheck: TreeProps["onCheck"] = (_checkedKeysValue, info) => {
    const { node, checked } = info;
    const { parentAtstMap, checkedItems } = this;

    if (checked) {
      checkedItems.add(node.key);
      const parents = parentAtstMap?.[String(node.key)];
      if (parents) {
        parents.forEach((parentKey) => checkedItems.add(parentKey));
      }
    } else {
      checkedItems.delete(node.key);
      if (node.children) {
        const childKeys = flattenItems(node.children, "children")?.map(
          ({ key }) => key,
        );
        childKeys?.forEach((childKey) => checkedItems.delete(childKey));
      }
    }
  };

  // expand

  expanded = new Set<React.Key>();

  setExpanded(list: React.Key[]) {
    this.expanded = new Set(list);
  }

  get expandedKeys(): React.Key[] {
    return Array.from(this.expanded);
  }

  get defaultExpanded() {
    return flattenItems(this.treeData, "children")?.flatMap(
      ({ children, key }) => (children ? [key] : []),
    );
  }

  onExpand: TreeProps["onExpand"] = (keys) => {
    this.setExpanded(keys);
  };
}
