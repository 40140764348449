import React from "react";
import { Input, Form, InputRef } from "antd";
import { delay } from "src/common/delay";
import { onError } from "src/common/onError";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { Obj2TabStore } from "../Obj2TabStore";
import { importObj } from "./importObj";

interface PropsObjImportModal {
  open: boolean;
  close(): void;
  store: Obj2TabStore;
}

interface ImportParams {
  xml: string;
}

const { TextArea } = Input;

export const ObjImportModal: React.FC<PropsObjImportModal> = (props) => {
  const { open, close, store } = props;
  const [form] = Form.useForm<ImportParams>();
  const inpRef = React.useRef<InputRef>(null);
  const [saving, setSaving] = React.useState(false);
  React.useEffect(() => {
    if (open) form.resetFields();
    delay(10).then(() => inpRef.current?.focus());
  }, [open]);
  const onSubmit = async ({ xml }: ImportParams) => {
    try {
      setSaving(true);
      const newObj = await importObj(xml);
      store.onNewObject(newObj);
      close();
    } catch (error) {
      onError(error);
    } finally {
      setSaving(false);
    }
  };
  return (
    <ModalVertFixed
      title="Импорт объекта"
      open={open}
      onCancel={close}
      okText="Импортировать"
      okButtonProps={{ htmlType: "submit", loading: saving }}
      // eslint-disable-next-line react/no-unstable-nested-components
      modalRender={(content) => (
        <Form<ImportParams> layout="vertical" onFinish={onSubmit} form={form}>
          {content}
        </Form>
      )}
    >
      <Form.Item name="xml" label="Содержимое xml" rules={[trimRequired()]}>
        <TextArea
          ref={inpRef}
          placeholder="Введите содержимое xml-файла с объектом"
        />
      </Form.Item>
    </ModalVertFixed>
  );
};
