import { z } from "zod";
import { zPermissionsList } from "./Permission";

export const zAttributeValue = z.object({
  id: z.number(),
  value: z.string().nullable(),
});
export type ZAttributeValue = z.infer<typeof zAttributeValue>;

export const zAttribute = z.object({
  id: z.number(),
  name: z.string(),
  valueType: z.number(),
  fieldKey: z.string().nullable().optional(),
  referenceId: z.number().nullable().optional(), // id справочника, если valueType - Справочник
  values: zAttributeValue.array().nullable().optional(),
  valueId: z.number().nullable().optional(), // Ссылка на значение справочника, если атрибут входит в Dictionary-группу
  roleIds: z.number().array().nullable().optional(), // LPLM-665
  // Свойства компонента на карточке сущности. Название неудачное, но и этого пришлось неделю ждать.
  viewStyles: z.string().nullable().optional(),
  viewType: z.string().nullable().optional(),
  permissions: zPermissionsList.nullable().optional(),
  iterator: z.boolean().optional(), // Только для valueType=AttrTypeName.object. Если true, то будет создаваться несколько экземпляров для каждого из выбранных значений данного атрибута.
  clearByCopy: z.boolean().optional(), // Очищать/не очищать значение атрибута при копировании
  pathValue: z.number().array().optional().nullable(), // нужен только для атрибута с типом AttrTypeName.linkedValue, для остальных = null, означает путь до присоединяемого атрибута
  parentAttributeId: z.number().nullable().optional(), // ID атрибута связанного объекта, с которым происходит сравнение
  linkAttributeId: z.number().nullable().optional(), // ID атрибута "Ссылка на другой объект" внутри объекта, со значением атрибута которого мы проводим сравнение
  withRestrictions: z.boolean().nullable().optional(), // Копировать/не копировать ограничения по значению (актуально только при сравнении справочников)
});
export type ZAttribute = z.infer<typeof zAttribute>;

export const zAttrLinkedData = z.object({
  entityId: z.number(),
  linkAttributeId: z.number(),
  attribute: zAttribute,
  values: z.string().array(),
});
export type ZAttrLinkedData = z.infer<typeof zAttrLinkedData>;
