import React from "react";
import { Form, Input, Select } from "antd";
import { observer } from "mobx-react-lite";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { Obj2TabStore } from "src/pages/ManagementPage/Obj2Tab";
import { filterOptionByName } from "src/common/filterOptionByName";
import { ZTechpackSettings } from "../TechpackTypes";

interface PropsTechpackSettings {
  store: Obj2TabStore;
}

const fieldKey = (key: keyof ZTechpackSettings) => key;

export const TechpackSettings: React.FC<PropsTechpackSettings> = observer(
  ({ store: { objectOptions } }) => (
    <>
      <Form.Item
        label="Объект для формирования Techpack"
        name={fieldKey("techpackObjectId")}
        rules={[{ required: true }]}
      >
        <Select
          options={objectOptions ?? []}
          filterOption={filterOptionByName}
          showSearch
          allowClear
        />
      </Form.Item>
      <Form.Item
        label="URL для формирования Techpack"
        name={fieldKey("techpackUrl")}
        rules={[trimRequired()]}
      >
        <Input allowClear />
      </Form.Item>
    </>
  ),
);
