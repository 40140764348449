import React from "react";
import { ChildAttsTreeItem } from "../EntityCopyButton";

export const makeParentAttsMap = (treeData: ChildAttsTreeItem[]) => {
  const map: Record<string, React.Key[]> = {};
  const buildMapping = (
    nodes: ChildAttsTreeItem[],
    parentKeys: React.Key[] = [],
  ) => {
    nodes.forEach((node) => {
      map[String(node.key)] = [...parentKeys];
      if (node.children) {
        buildMapping(node.children, [...parentKeys, node.key]);
      }
    });
  };
  buildMapping(treeData);
  return map;
};
