import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Input, Select } from "antd";
import { t } from "i18next";
import { NewMeasurementChartEntityData } from "../../ZMeasurementChart";
import { MChartEntityStore } from "../MChartEntityStore";
import styles from "./NewMCEntity.module.less";
import { NumOption } from "../EditMCEntity/MChartTable/PointDict";

interface PropsNewMCEntity {
  store: MChartEntityStore;
}

const fkey = (key: keyof NewMeasurementChartEntityData) => key;

export const NewMCEntity: React.FC<PropsNewMCEntity> = observer(({ store }) => {
  const [form] = Form.useForm();
  const sizeId: number | null = Form.useWatch(fkey("sizeLineEntityId"), form);
  React.useEffect(() => {
    store.updateBaseSizeOptions(sizeId);
    form.resetFields([fkey("baseSizeEntityId")]);
  }, [sizeId]);
  return (
    <Form<NewMeasurementChartEntityData>
      form={form}
      className={styles.form}
      layout="vertical"
      onFinish={(values) => store.create(values)}
    >
      <Form.Item
        label="Size line"
        name={fkey("sizeLineEntityId")}
        rules={[{ required: true }]}
      >
        <Select
          options={store.sizeLineOptions}
          allowClear
          showSearch
          filterOption={filterOption}
        />
      </Form.Item>
      <Form.Item
        label="Base Size"
        name={fkey("baseSizeEntityId")}
        rules={[{ required: true }]}
      >
        <Select
          options={store.baseSizeOptions}
          allowClear
          showSearch
          filterOption={filterOption}
          loading={store.loadingBaseSize}
        />
      </Form.Item>
      <Form.Item label="Name" name={fkey("mcName")}>
        <Input allowClear />
      </Form.Item>
      <Form.Item label="Comment" name={fkey("comment")}>
        <Input allowClear />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={store.buzy === "create"}
        >
          {t("Create")}
        </Button>
      </Form.Item>
    </Form>
  );
});

const filterOption = (input: string, option?: NumOption) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
