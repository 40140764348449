export default {
  translation: {
    "Access denied": "Доступ запрещен",
    "Action is irreversible": "Это действие необратимо",
    Add: "Добавить",
    "Advanced settings": "Дополнительные настройки",
    "All attributes": "Все атрибуты",
    all: "все",
    "Appeal to technical support is registered":
      "Обращение в техподдержку зарегистрировано",
    Apply: "Применить",
    "Are you sure to delete {{count}} persons?":
      "Удалить {{count}} пользователей?",
    "Are you sure to delete {{count}} persons?_one":
      "Удалить {{count}} пользователя?",
    "Are you sure to delete selected positions?":
      "Вы уверены, что хотите удалить выбранные позиции?",
    "Attached files": "Вложенные файлы",
    "Attention!": "Внимание!",
    Attributes: "Атрибуты",
    'Attributes to display the role "{{role}}"':
      'Атрибуты для отображения роли "{{role}}"',
    "Authorization required": "Требуется авторизация",
    "Bussiness dictionaries": "Бизнес-справочники",
    Cancel: "Отмена",
    "Change of state": "Смена состояния",
    "Change password": "Изменение пароля",
    ChangingTheStateOfAnInstance_one: "Изменение состояния экземпляра",
    ChangingTheStateOfAnInstance_many: "Изменение состояния экземпляров",
    "Click or drag the file/s into the area to upload":
      "Нажмите или перетащите файл/ы в область для загрузки",
    Close: "Закрыть",
    "Collapse menu": "Свернуть меню",
    "Communication between objects is not configured. Contact your administrator.":
      "Не настроена связь между объектами. Обратитесь к администратору.",
    "Contacting tech support": "Обращение в техподдержку",
    "Copied instances": "Копируемые экземпляры",
    Copy: "Копировать",
    "Copy to": "Копировать В...",
    "Copy to another instance from current instance":
      "Копирование В другой экземпляр ИЗ текущего",
    "Copy from": "Копировать ИЗ...",
    "Copy from another instance to the current instance":
      "Копирование ИЗ другого экземпляра В текущий",
    "Copying an instance": "Копирование экземпляра",
    "Copy source": "Источник копирования",
    Create: "Создать",
    "Current password": "Текущий пароль",
    "Current state": "Текущее состояние",
    Dashboards: "Дашборды",
    "Data not loaded": "Данные не загружены",
    "Data updated": "Выполнено обновление данных",
    DateFormat: "DD.MM.YYYY",
    DateTimeFormat: "DD.MM.YYYY HH:mm",
    Delete: "Удалить",
    "Deletion is impossible": "Удаление невозможно",
    "Describe the question": "Опишите вопрос",
    Dictionaries: "Справочники",
    "Display available only for created instances":
      "Отображение доступно только для созданных экземпляров",
    "Do you want to delete the current entry?":
      "Выполнить удаление текущей записи?",
    "Doesn't match": "Не совпадает",
    "Do you want to delete the selected records?":
      "Выполнить удаление выделенных записей?",
    "Enter a name": "Введите имя",
    "Enter email": "Введите email",
    "Enter the Email you used when registering your account":
      "Введите Email, который вы использовали при регистрации учетной записи. Мы отправим вам ссылку для восстановления пароля.",
    "Enter information to register on the platform":
      "Внесите данные для регистрации на платформе",
    "EntityId value not received": "Не получено значение entityId",
    Entity_one: "Сущность",
    Entity_few: "Сущности",
    Error: "Ошибка",
    "Error when accessing the server": "Ошибка при обращении к серверу",
    "Executed instance creation": "Выполнено создание экземпляра",
    "Find an attribute": "Найти атрибут",
    Finish: "Завершить",
    "Format: value": "Формат: {{value}}",
    From: "От",
    "Full name": "Ф.И.О.",
    "Image modification": "Изменение изображений",
    "General information": "Общая информация",
    "Incorrect current password": "Указан неверный текущий пароль",
    "Incorrect format of received data": "Ошибочный формат полученных данных",
    "Instance of object": "Экземпляр объекта {{name}}",
    "Instructions for working in the system": "Инструкция по работе в системе",
    "Invalid email format": "Неверный формат email",
    "Invalid page URL": "Неверный URL-адрес страницы",
    "It is required to correctly fill in the fields on the form":
      "Требуется правильно заполнить поля на форме",
    "Hierarchy structure": "Структура иерархии",
    "History of changes": "История изменений",
    "Groups and roles": "Группы и роли",
    "Letter has been sent": "Письмо отправлено",
    Loading: "Загрузка",
    Login: "Вход",
    Logout: "Выйти",
    Main: "Главное",
    "Main title": "Главная",
    Management: "Управление",
    "Mark everything as read": "Отметить всё прочитанным",
    "Marked by reading": "Отметить прочитанным",
    "Maximum number: count": "Максимальное количество: {{count}}",
    "Maximum total size:": "Максимальный общий размер: {{size}} Мб",
    "Menu settings": "Настройка меню",
    Models: "Модели",
    Modify: "Изменить",
    "New instance": "Новый экземпляр",
    "New instance has been created": "Создан новый экземпляр",
    "New instance of object": "Новый экземпляр объекта {{name}}",
    "New instances were created": "Создано {{count}} новых экземпляров",
    "New instances were created_one": "Создан {{count}} новый экземпляр",
    "New instances were created_few": "Создано {{count}} новых экземпляра",
    "New password": "Новый пароль",
    "New position": "Новая позиция",
    "Next state": "Следующее состояние",
    "No available states for transition":
      "Нет доступных состояний для перехода",
    "No match for path {path}": "Нет соответствия пути {path}",
    "No object specified": "Не указан объект",
    Notifications: "Уведомления",
    "Objects and attributes": "Объекты и атрибуты",
    Or: "Или",
    Password: "Пароль",
    "Password change completed": "Выполнена смена пароля",
    "Password must contain": "Пароль должен содержать",
    "Password recovery": "Восстановление пароля",
    Persons: "Пользователи",
    Question: "Вопрос",
    Refresh: "Обновить",
    Register: "Зарегистрироваться",
    "Registration of object": 'Регистрация объекта "{{obj}}"',
    Repeat: "Повторить",
    "Repeat new password": "Повтор нового пароля",
    Reset: "Сбросить",
    "Restore password": "Восстановить пароль",
    "Return to the authorization page": "Вернуться на страницу авторизации",
    Save: "Сохранить",
    "Saving is not possible. Status: {{status}}":
      "Сохранение невозможно. Статус: {{status}}",
    Search: "Поиск",
    Send: "Отправить",
    "Send link": "Отправить ссылку",
    "Server error": "Ошибка сервера",
    Setting: "Настройки",
    Select: "Выбрать",
    "Select dependent objects to copy":
      "Выберите зависимые объекты для копирования:",
    "Select the instance to which you want to copy the previously selected instances of the linked object with all relationships":
      "Выберете экземпляр, в который необходимо скопировать ранее выбранные экземпляры связанного объекта со всеми связями",
    "Selected entities": "Выбранные сущности",
    "Selected lines count": "Выбрано {{count}} строк",
    "Selected lines count_one": "Выбрана {{count}} строка",
    "Selected lines count_few": "Выбрано {{count}} строки",
    "Select the desired menu item to start operation":
      "Выберите нужный пункт в меню для начала работы",
    "Select the instance from which you want to copy the instances of the linked object with all the relationships":
      "Выберете экземпляр, из которого необходимо скопировать экземпляры связанного объекта со всеми связями",
    "Select the instances of the linked object that you want to copy to the current instance":
      "Выберете экземпляры связанного объекта, которые необходимо скопировать в текущий экземпляр",
    "Send registration request": "Отправить запрос на регистрацию",
    "Sign In": "Войти",
    "Show for the period": "Показать за период",
    "Specify the topic": "Укажите тему",
    "SSO Authorization": "Авторизация через SSO",
    State: "Состояние",
    "State change performed": "Выполнено изменение состояния",
    Status: "Статус",
    Subject: "Тема",
    Submit: "Отправить",
    "Table export": " Экспорт таблицы",
    "Tech support": "Техподдержка",
    templates: "шаблоны",
    "The field is mandatory": "Поле является обязательным",
    "The instance is missing": "Экземпляр отсутствует",
    "The link must start with":
      "Ссылка должна начинаться с http:// или https://",
    "The state has been deleted": "Состояние было удалено",
    "The service has not been configured": "Не выполнена настройка сервиса",
    "There is no user with the specified data":
      "Нет пользователя с указанными данными",
    To: "До",
    "To home": "На главную",
    Total: "Итог",
    "Total files size exceeds": "Общий размер файлов превышает {{size}} Мб",
    "Total models: {{count}}": "Всего моделей: {{count}}",
    "Total information": "Суммарная информация",
    "Total instances count": "Всего экземпляров: {{count}}",
    "Total persons count": "Всего {{count}} пользователей",
    "Total persons count_one": "Всего {{count}} пользователь",
    "Total persons count_few": "Всего {{count}} пользователя",
    Upload: "Загрузить",
    User: "Пользователь",
    UserAccount: "Личный кабинет",
    "Your request has been registered by the technical support service":
      "Ваше обращение зарегистрировано службой технической поддержки",
    "Welcome to the Omnidata platform!":
      "Добро пожаловать на платформу Omnidata!",
    "When saving, multiple instances of the object will be created":
      'При сохранении будет создано несколько экземпляров объекта "{{obj}}", по одному на каждое выбранное значение атрибута "{{attr}}"',

    actionType: {
      // see ActionTypeName
      ATTRIBUTE: "Атрибут",
      OBJECT: "Объект",
      ENTITY: "Экземпляр",
    },
    attrType: {
      BOOLEAN: "Логическое значение",
      CHILD_OBJECT_ID: "Список зависимых объектов",
      DATE: "Дата",
      DATE_WITH_TIME: "Дата и время",
      OBJECT_ID: "Ссылка на другой объект",
      ONE_CHOICE_DICTIONARY: "Справочник",
      FILE: "Файл",
      FORMULA: "Формула",
      IMAGE: "Изображение",
      INT: "Целое число",
      LINK_TO_EXTERNAL_SOURCE: "Ссылка на внешний ресурс",
      LINKED_VALUE: "Ссылка на значение",
      MULTIPLE_CHOICE_DICTIONARY: "Множественный выбор из справочника",
      NUMERIC: "Дробное число",
      STRING: "Текст",
      SYSTEM_LANGUAGE: "Язык системы",
      TIME: "Время",
      USER_FILTER: "Список пользователей",
    },
    groupType: {
      ByDictionary: "На основе справочника",
      Mnemonic: "Мнемоническая",
      Dictionary: "Справочник",
    },
    management: {
      AddGroup: "Добавить группу",
      "Confirm deletion of dictionary group":
        "Вы действительно хотите удалить группу справочников?",
      "Confirm deletion of dictionary":
        "Вы действительно хотите удалить справочник?",
      "Deleting a dictionary group": "Удаление группы справочников",
      "Group name": "Название группы",
      "New group of dictionaries": "Новая группа справочников",
      "Wrong group type": "Неверный формат группы",
    },
    Pages: {
      Plans: "Планы",
      Projects: "Проекты",
      Reports: "Отчёты",
    },
    PasswordProps: {
      "at least N characters": "не менее {{count}} символов",
      "contain numbers": "содержать цифры {{n}}",
      "uppercase Latin letters": "прописные латинские буквы {{n}}",
      "lowercase Latin letters": "строчные латинские буквы {{n}}",
      "special characters": "специальные символы",
      "password cannot be the same as login":
        "пароль не может совпадать с логином",
      "Not done": "Не выполнено: {{n}}",
    },
    vaidation: {
      required: "Поле обязательно для заполнения",
    },
  },
};
