// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__tableBox--TnQWO {
  flex: 1;
  overflow: hidden;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__tableHeader--RMwyN {
  display: grid;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cellTop--gB4G2 {
  border-top: thin solid #E1E3E6;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cellLeft--UY2OO {
  border-left: thin solid #E1E3E6;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cell--gx9Iw {
  border-right: thin solid #E1E3E6;
  border-bottom: thin solid #E1E3E6;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cellRow--qui31 {
  color: #343A41;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  overflow: hidden;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__baseSizeCell--TfLQs {
  background-color: #EFF5FF;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cellHd--h6d2m {
  color: #767C84;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cellCentered--aNSCK {
  text-align: center;
  padding-top: 4px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__pad--NnRl1 {
  padding: 8px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__subtitle--urIvN {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__subtitle--urIvN:hover .src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__button--Nfg04 {
  visibility: visible;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__text--3yHJZ {
  padding: 8px;
  flex: 1;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__button--Nfg04 {
  visibility: hidden;
  transition: visibility 0.2s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/MChartTable/MChartTable.module.less","webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/MChartTable/MCSubtitle.module.less"],"names":[],"mappings":"AAEA;EACI,OAAA;EACA,gBAAA;ACDJ;ADIA;EACI,aAAA;ACFJ;ADKA;EACI,8BAAA;ACHJ;ADKA;EACI,+BAAA;ACHJ;ADMA;EACI,gCAAA;EACA,iCAAA;ACJJ;ADOA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;ACLJ;ADOA;EACI,yBAAA;ACLJ;ADQA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;ACNJ;ADQA;EACI,kBAAA;EACA,gBAAA;ACNJ;ADQA;EACI,YAAA;ACNJ;AAvCA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AAyCJ;AAxCI;EACI,mBAAA;AA0CR;AAvCA;EDqCI,YAAA;ECnCA,OAAA;AAyCJ;AAvCA;EACI,kBAAA;EACA,uCAAA;AAyCJ","sourcesContent":["@border: thin solid #E1E3E6;\n\n.tableBox {\n    flex: 1;\n    overflow: hidden;\n}\n\n.tableHeader {\n    display: grid;\n}\n\n.cellTop {\n    border-top: @border;\n}\n.cellLeft {\n    border-left: @border;\n}\n\n.cell {\n    border-right: @border;\n    border-bottom: @border;    \n}\n\n.cellRow {\n    color: #343A41;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n    overflow: hidden;\n}\n.baseSizeCell {\n    background-color: #EFF5FF;\n}\n\n.cellHd {\n    color: #767C84;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;    \n}\n.cellCentered {\n    text-align: center;\n    padding-top: 4px;\n}\n.pad {\n    padding: 8px;\n}","@import \"./MChartTable.module.less\";\n\n.subtitle {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    &:hover .button {\n        visibility: visible;\n    }\n}\n.text {\n    .pad;\n    flex: 1;\n}\n.button {\n    visibility: hidden;\n    transition: visibility 0.2s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__tableBox--TnQWO`,
	"tableHeader": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__tableHeader--RMwyN`,
	"cellTop": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cellTop--gB4G2`,
	"cellLeft": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cellLeft--UY2OO`,
	"cell": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cell--gx9Iw`,
	"cellRow": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cellRow--qui31`,
	"baseSizeCell": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__baseSizeCell--TfLQs`,
	"cellHd": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cellHd--h6d2m`,
	"cellCentered": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__cellCentered--aNSCK`,
	"pad": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__pad--NnRl1`,
	"subtitle": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__subtitle--urIvN`,
	"button": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__button--Nfg04`,
	"text": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCSubtitle-module__text--3yHJZ`
};
export default ___CSS_LOADER_EXPORT___;
