import React, { useMemo, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { observer } from "mobx-react-lite";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { EntityFiltersPageStore } from "../../EntityFiltersPageStore";
import styles from "./EntityActions.module.less";
import { EntityExportButton } from "../EntityExportButton";
import { ChangeStateBulkButton } from "../ChangeStateBulkButton/ChangeStateBulkButton";
import { EntFilterActionType } from "../../EntityFiltersPage.types";
import { EntityCopyButton } from "../EntityCopyButton";

interface PropsEntityActions {
  store: EntityFiltersPageStore;
}

export const EntityActions: React.FC<PropsEntityActions> = observer(
  ({ store: { currObjId, tableStore, currObjName, avalibleActionsSet } }) => {
    if (!currObjId || !tableStore) return null;
    const [loading, setLoading] = useState(false);

    const EntityActionsMap: Partial<Record<EntFilterActionType, ItemType>> = {
      [EntFilterActionType.changeState]: {
        key: "stateChange",
        label: (
          <ChangeStateBulkButton
            store={tableStore}
            objectId={Number(currObjId)}
          />
        ),
      },
      [EntFilterActionType.export]: {
        key: "export",
        label: (
          <EntityExportButton store={tableStore} objectName={currObjName} />
        ),
      },
      [EntFilterActionType.copy]: {
        key: "copy",
        label: (
          <EntityCopyButton
            tableStore={tableStore}
            objectId={currObjId}
            onLoad={setLoading}
          />
        ),
      },
    };

    const menuProps: MenuProps = useMemo(() => {
      const items = Object.entries(EntityActionsMap)
        .filter(([key]) => avalibleActionsSet.has(key as EntFilterActionType))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([_key, item]) => [item, { type: "divider" } as ItemType])
        .flat();
      items.pop();
      return { items };
    }, []);

    if (!menuProps.items?.length) return null;

    return (
      <Dropdown menu={menuProps} placement="bottomRight">
        <Button
          id="entityListActions"
          icon={<MoreOutlined className={styles.icon} />}
          loading={loading}
          className={styles.menuButton}
        />
      </Dropdown>
    );
  },
);
