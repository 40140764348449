import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { theme, Button, Checkbox, Dropdown, Form, Modal } from "antd";
import {
  DeleteOutlined,
  DeleteRowOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
  FunnelPlotOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { MChartEntityStore } from "../MChartEntityStore";
import { MCInfoItem } from "./MCInfoItem";
import { MChartTable } from "./MChartTable/MChartTable";
import styles from "./EditMCEntity.module.less";

const { useToken } = theme;

interface PropsEditMCEntity {
  store: MChartEntityStore;
}

export const EditMCEntity: React.FC<PropsEditMCEntity> = observer(
  ({ store }) => {
    const { buzy, data, selected, hiddenColumns } = store;
    const [form] = Form.useForm();
    if (data.status !== "ready") return null;
    const { mc } = data.result;
    const disabled = !!buzy;
    const onReset = () => {
      Modal.confirm({
        title: t("Attention!"),
        content: "Все данные будут удалены.",
        icon: <ExclamationCircleOutlined />,
        okButtonProps: { danger: true },
        onOk() {
          store.reset();
        },
      });
    };
    const onDeleteRow = () => {
      Modal.confirm({
        title: t("Do you want to delete the selected records?"),
        icon: <ExclamationCircleOutlined />,
        okText: t("Delete"),
        okButtonProps: { danger: true },
        onOk() {
          store.deleteSelected();
        },
      });
    };
    const { token } = useToken();

    const contentStyle: React.CSSProperties = {
      backgroundColor: token.colorBgElevated,
      borderRadius: token.borderRadiusLG,
      boxShadow: token.boxShadowSecondary,
    };

    return (
      <Form
        form={form}
        className={styles.commonBox}
        onFinish={(values) => {
          store.save(values);
        }}
      >
        <div className={styles.infoRow}>
          <MCInfoItem value={store.curSizeLineName} label="Size Scale" />
          <MCInfoItem value={store.curBaseSizeName} label="Base Size" />
          <MCInfoItem
            value={mc.measurementChartEntityDto?.comment}
            label="Comment"
          />
        </div>
        <div className={styles.toolsRow}>
          <div className={styles.selectInfo}>
            {selected.size > 0 &&
              t("Selected lines count", { count: selected.size })}
          </div>
          <Dropdown
            trigger={["click"]}
            dropdownRender={() => (
              <div style={contentStyle} className={styles.dropDownBox}>
                {store.sizeVersionsList.map(({ key, szName, verName }) => (
                  <div key={key}>
                    <Checkbox
                      checked={!hiddenColumns.has(key)}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        store.toggleColumnVisibility(key);
                      }}
                    >
                      {szName} - {verName}
                    </Checkbox>
                  </div>
                ))}
              </div>
            )}
          >
            <Button
              icon={
                hiddenColumns.size ? <FunnelPlotOutlined /> : <FilterOutlined />
              }
              title="Columns visibility"
            />
          </Dropdown>
          <Button
            icon={<DeleteRowOutlined />}
            disabled={disabled || selected.size === 0}
            onClick={onDeleteRow}
          >
            Delete selected
          </Button>
          <Button
            icon={<PlusCircleOutlined />}
            disabled={disabled}
            onClick={() => store.addRow(form)}
          >
            {t("Add point")}
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={onReset}
            loading={buzy === "reset"}
            disabled={disabled}
          >
            Начать заново
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabled}
            loading={buzy === "save"}
          >
            {t("Save")}
          </Button>
        </div>
        <MChartTable store={store} />
      </Form>
    );
  },
);
