import * as React from "react";
import { t } from "i18next";
import { Form, Input, InputRef, Select, Switch } from "antd";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { IdLabel } from "src/references/getIdNames";
import { AttrTypeName, isAttrDictionary } from "src/types/AttrType";
import { ZIdName } from "src/types/ZIdName";
import { ZAttribute } from "src/types/ZAttribute";
import { MultiLangFields } from "src/components/MultiLangFields";
import { ObjIteratorMode } from "src/pages/ManagementPage/objIterator";
import { FieldsWithTitle } from "src/pages/ManagementPage/FieldsWithTitle";
import { WidthLimitedFields } from "src/pages/ManagementPage/WidthLimitedFields";
import { edAttrField } from "../../../EdAttribute";
import { SelectRoles } from "../SelectRoles";
import { ZRolesGroup } from "../../../roles/roleTypes";
import { SelectDictionary, SelectDictionaryStore } from "../SelectDictionary";
import { SelectObject } from "../SelectObject";
import { EditorInfo, ViewInfo } from "../MetaInfo";
import styles from "./AttrCommonFields.module.less";
import { validatorOfId } from "../attrValidators";
import { getTypeIcon } from "../../../utils/typeIcons";
import { newItemId } from "../../../Obj2Nodes";
import { LinkedValuePath } from "./LinkedValuePath";

interface DataSource {
  attrTypesList: IdLabel[];
  attrTypesDict: Record<number, string>;
  roleGroups: ZRolesGroup[];
  selectDictionaryStore: SelectDictionaryStore;
  readonly currentObjectId: number | null;
}

interface PropsAttrCommonFields {
  attrKey: React.Key;
  attrId: number;
  objectId: number;
  disabled: boolean;
  dataSource: DataSource;

  isFieldUnique: (
    field: keyof ZAttribute,
    attrKey: React.Key,
    attrId: number,
    value: unknown,
  ) => boolean;
  loadObjectOptions(): Promise<ZIdName[]>;
  objIteratorMode: ObjIteratorMode;
}

export const AttrCommonFields: React.FC<PropsAttrCommonFields> = (props) => {
  const {
    objectId,
    attrId,
    attrKey,
    disabled,
    dataSource,
    isFieldUnique,
    loadObjectOptions,
    objIteratorMode,
  } = props;
  const {
    attrTypesList,
    attrTypesDict,
    roleGroups,
    selectDictionaryStore,
    currentObjectId,
  } = dataSource;
  // Установка фокуса на первое поле
  const ref1 = React.useRef<InputRef>(null);
  React.useEffect(() => {
    setTimeout(() => ref1.current?.focus(), 10);
  }, [attrKey]);

  const valueType = Form.useWatch(edAttrField.valueType);
  const typeName = attrTypesDict[valueType];
  const typeFilter = (typeId: number): boolean =>
    isAttrDictionary(attrTypesDict[typeId]);
  const dictVisible = typeFilter(valueType);
  const objVisible =
    typeName === AttrTypeName.object || typeName === AttrTypeName.childEntities;
  const roleIdsVisible = attrTypesDict[valueType] === AttrTypeName.usersList;
  const isLinkedValue = typeName === AttrTypeName.linkedValue;
  const clearByCopyVisible = typeName !== AttrTypeName.childEntities;

  return (
    <div className={styles.common}>
      <WidthLimitedFields>
        <FieldsWithTitle title="Название атрибута">
          <Form.Item
            name={edAttrField.name}
            label="Системное название"
            rules={[
              trimRequired(),
              {
                validator: (_rule, value) =>
                  isFieldUnique("name", attrKey, attrId, value)
                    ? Promise.resolve()
                    : Promise.reject(Error("Название уже используется")),
              },
            ]}
          >
            <Input
              allowClear
              ref={ref1}
              disabled={disabled || attrId !== newItemId}
            />
          </Form.Item>
          <MultiLangFields basePath={[edAttrField.translations]} />
        </FieldsWithTitle>

        <FieldsWithTitle title="Настройки атрибута">
          <div className={styles.typeBlock}>
            <Form.Item
              className="idAttrType"
              name={edAttrField.valueType}
              label="Тип атрибута"
              rules={[{ required: true }]}
            >
              <TypeSelect
                types={attrTypesList}
                disabled={disabled || attrId !== newItemId}
              />
            </Form.Item>
            <Form.Item
              name={edAttrField.fieldKey}
              label="Идентификатор"
              tooltip="Для программного доступа к атрибуту"
              rules={[
                validatorOfId,
                {
                  validator: (_rule, value) =>
                    isFieldUnique("fieldKey", attrKey, attrId, value)
                      ? Promise.resolve()
                      : Promise.reject(Error("Идентификатор уже использован")),
                },
              ]}
            >
              <Input allowClear disabled={disabled} />
            </Form.Item>
          </div>
        </FieldsWithTitle>
        {isLinkedValue && (
          <Form.Item
            name={edAttrField.pathValue}
            label="Путь к атрибуту"
            rules={[{ required: true }]}
          >
            <LinkedValuePath
              currAttrId={attrId}
              objectId={objectId}
              attrTypesDict={attrTypesDict}
            />
          </Form.Item>
        )}
        {dictVisible && (
          <Form.Item
            name={edAttrField.referenceId}
            label="Справочник"
            rules={[{ required: true }]}
            className="idReferenceToDict"
          >
            <SelectDictionary
              store={selectDictionaryStore}
              disabled={disabled}
            />
          </Form.Item>
        )}

        {objVisible && (
          <Form.Item
            name={edAttrField.referenceId}
            label={t("attrType.OBJECT_ID")}
            rules={[{ required: true }]}
          >
            <SelectObject
              loadObjectOptions={loadObjectOptions}
              disabled={disabled}
            />
          </Form.Item>
        )}

        {roleIdsVisible && (
          <Form.Item name={edAttrField.roleIds} label="Доступные группы ролей">
            <SelectRoles roleGroups={roleGroups} />
          </Form.Item>
        )}

        {objIteratorMode !== "hidden" && (
          <Form.Item
            name={edAttrField.iterator}
            label={`Является итератором ${objIteratorMode === "blocked" ? "(У данного объекта уже есть итератор)" : ""}`}
            tooltip="Означает, что будет создаваться несколько экземпляров - для каждого из выбранных значений атрибута. В объекте может быть только один итератор."
          >
            <Switch disabled={objIteratorMode !== "enabled"} />
          </Form.Item>
        )}

        {clearByCopyVisible && (
          <Form.Item
            name={edAttrField.clearByCopy}
            label="Очищать при копировании"
            tooltip="Означает, что при копировании экземпляра в копии значение атрибута будет очищено"
          >
            <Switch />
          </Form.Item>
        )}
      </WidthLimitedFields>

      <FieldsWithTitle title="Отображение атрибута">
        <div className={styles.twoCols}>
          <EditorInfo
            attrTypesDict={attrTypesDict}
            disabled={disabled}
            objectId={objectId}
            curAttrId={attrId}
          />
          <ViewInfo
            attrTypesDict={attrTypesDict}
            currentObjectId={currentObjectId}
          />
        </div>
      </FieldsWithTitle>
    </div>
  );
};

type PropsTypeSelect = {
  types: IdLabel[];
  value?: number;
  onChange?(v: number): void;
  disabled: boolean;
};
const TypeSelect: React.FC<PropsTypeSelect> = (props) => {
  const { types, value, onChange, disabled } = props;
  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={(type) => {
        onChange?.(type);
      }}
      options={types.map(({ id, label }) => ({
        value: id,
        label: (
          <div className={styles.typeOption}>
            {getTypeIcon(id)} {label}
          </div>
        ),
      }))}
    />
  );
};
TypeSelect.defaultProps = {
  value: undefined,
  onChange: undefined,
};
