import { makeAutoObservable } from "mobx";
import { ZObjectRefTable } from "src/common/attrEdit/components";
import { ZAttrViewInfo } from "src/common/attrView";
import { onError } from "src/common/onError";
import { EntFilterActionType } from "src/pages/EntityFiltersPage/EntityFiltersPage.types";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import {
  loadObjectAttrinbutesReduced,
  makeObjectAttributesMap,
} from "src/pages/ManagementPage/objectsApi";
import { ZAttribute } from "src/types/ZAttribute";

export class ObjectRefTableStore {
  filtersPageStore: EntityFiltersPageStore | null = null;

  initialValues: string[] | null = null;

  setInitialValues(list: string[]) {
    this.initialValues = list;
  }

  attrInfo: ZAttribute | null = null;

  setAttrInfo(data: ZAttribute) {
    this.attrInfo = data;
  }

  component: ZObjectRefTable | null = null;

  setComponent(data: ZObjectRefTable) {
    this.component = data;
  }

  viewInfo: ZAttrViewInfo | null = null;

  setViewInfo(data: ZAttrViewInfo | undefined) {
    this.viewInfo = data ?? null;
  }

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  attsMetaMap: Record<number, ZAttribute> = {};

  setAttsMetaMap(map: Record<number, ZAttribute>) {
    this.attsMetaMap = map;
  }

  async init(
    attrInfo: ZAttribute,
    component: ZObjectRefTable,
    viewInfo: ZAttrViewInfo,
    value?: string[],
    onChange?: (value?: string[]) => void,
  ) {
    try {
      this.setLoading(true);
      if (!attrInfo.referenceId) throw new Error("wrong attribute data!");

      const safeValues = value || [];
      const objectId = attrInfo.referenceId;
      const { multiple, isCreateButtonAvailable } = component;

      this.setAttrInfo(attrInfo);
      this.setComponent(component);
      this.setViewInfo(viewInfo);
      this.setInitialValues(safeValues);
      this.setAttsMetaMap(
        makeObjectAttributesMap(
          await loadObjectAttrinbutesReduced(String(objectId)),
        ),
      );

      const actions = new Set([
        EntFilterActionType.changeState,
        EntFilterActionType.export,
      ]);

      if (isCreateButtonAvailable) {
        actions.add(EntFilterActionType.create);
      }

      this.filtersPageStore = new EntityFiltersPageStore({
        objectId,
        selectionSettings: {
          keepSelected: true,
          onSelect(rows) {
            onChange?.(rows.map((row) => String(row.id)));
          },
          selectionType: multiple ? "checkbox" : "radio",
        },
        onLoad: () => {
          this.syncSelected(value || []);
        },
        actions,
      });
      this.filtersPageStore.init(objectId);
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  syncSelected(values: string[]) {
    const tableStore = this.filtersPageStore?.tableStore;
    if (!tableStore) return;
    const allRows = tableStore.rows;
    const needSelectKeys = new Set(values.map(Number));
    const needSelectRows = allRows.filter((row) => needSelectKeys.has(row.id));
    tableStore?.safeSelect(needSelectRows);
  }

  constructor() {
    makeAutoObservable(this);
  }
}
