import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { observer } from "mobx-react-lite";
import { ZPersonRow } from "src/types/ZPersonRow";
import { ZObjectItem } from "src/types/ZObjectItem";
import { ZRolesViewAtts } from "src/common/roles/ZRolesViewAtts";
import { apiLoadPersonList } from "src/common/apiPerson";
import { loadRolesDictCached } from "src/common/apiRoles";
import { onError } from "src/common/onError";
import { PersonLabel } from "src/common/attrView/components/PersonCellInfo";
import { DefaultOptionType } from "antd/es/select";
import { ZAttribute } from "src/types/ZAttribute";
import { AttrPersonSelectProps } from "src/common/attrEdit/components/ZAttrPersonSelect";

export type PropsPersonSelect = AttrPersonSelectProps & {
  value?: string[] | null;
  rolesViewAtts?: ZRolesViewAtts;
  onChange?(newValue?: string[] | null): void;
  attr: ZAttribute;
  disabled?: boolean;
  allowClear?: boolean;
  multiple?: boolean;
};

export const PersonSelect: React.FC<PropsPersonSelect> = observer(
  ({
    value,
    onChange,
    disabled = false,
    attr,
    multiple,
    allowClear = false,
    rolesViewAtts,
  }) => {
    const roleIds = attr.roleIds ?? [];
    const [loading, setLoading] = useState(false);
    const [persons, setPersons] = useState<ZPersonRow[]>([]);
    const [rolesDict, setRolesDict] = useState<Record<number, ZObjectItem>>({});

    const init = async () => {
      try {
        setLoading(true);

        const [rows, dict] = await Promise.all([
          apiLoadPersonList({ roleIds, userIds: [] }),
          loadRolesDictCached(),
        ]);

        setRolesDict(dict);
        setPersons(rows);
      } catch (error) {
        onError(error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      init();
    }, []);

    const options: DefaultOptionType[] = persons.map((person) => {
      const searchTextFilter = person.attributeValues
        .map((attribute) => attribute.values?.join(" "))
        .filter(Boolean)
        .join(" ");
      // todo: нужно будет продумать и потом подобные функции вынести, если будут повторяться

      return {
        label: (
          <PersonLabel
            key={person.id}
            person={person}
            rolesViewAtts={rolesViewAtts}
            rolesDict={rolesDict}
          />
        ),
        value: person.userId,
        searchText: searchTextFilter.toLowerCase(),
      };
    });

    return (
      <Select
        value={value}
        loading={loading}
        options={options}
        showSearch
        disabled={disabled}
        mode={multiple ? "multiple" : undefined}
        allowClear={allowClear}
        onChange={onChange}
        filterOption={(input, option) =>
          option?.searchText.indexOf(input.toLowerCase()) >= 0
        }
      />
    );
  },
);
