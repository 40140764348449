import {
  getAttributesFromObject,
  loadObject,
} from "src/pages/EntityCardPage/apiEntityCard";
import {
  apiAddExistingAttr,
  createAttributeInObject,
  createAttrInGroup,
  createAttrInValue,
  createGroupInGroup,
  createGroupInObj,
  createGroupInValue,
  createObject,
  loadFromAttr,
  loadRestrictions,
  loadValueThings,
  saveRestrictions,
} from "src/pages/ManagementPage/objectsApi";
import { ZObjectItem } from "src/types/ZObjectItem";
import { GroupType, ZGroup } from "src/types/ZGroup";
import { ZAttribute } from "src/types/ZAttribute";
import { ZIdName } from "src/types/ZIdName";
import { ZObjState } from "src/types/ZObjState";
import { ZRestriction } from "src/types/ZRestriction";
import {
  createObjectState,
  loadAttributePermissions,
  loadObjectStates,
  saveAttributePermissions,
  saveObjectRolesMap,
} from "../../../roles/rolesApi";
import {
  loadNotifyTemplates,
  saveNotifyTemplates,
} from "../../../NotifyTemplates/apiNotifyTemplates";
import { makeRolesMap } from "../../../EdObject";

/* eslint no-param-reassign: "off" */
/* eslint no-await-in-loop: "off" */
/* eslint no-plusplus: "off" */

export type CopyParams = {
  name: string;
  attrs: number[];
};

type CmdObj = {
  cmd: "obj";
  srcObjId: number;
  dstObjId?: number;
  used?: boolean;
};

type CmdGroup = {
  cmd: "group";
  owner: CmdGroup | CmdObj | CmdVal;
  used?: boolean;
  srcGroup: ZGroup;
  dstGroup?: ZGroup;
};

type CmdDict = {
  cmd: "dict";
  owner: CmdGroup | CmdObj | CmdVal;
  srcGroup: ZGroup;
  dstGroup?: ZGroup;
  used?: boolean;
};

type CmdVal = {
  cmd: "val";
  val: ZIdName;
  owner: CmdDict;
  used?: boolean;
};

type CmdAttr = {
  cmd: "attr";
  owner: CmdGroup | CmdObj | CmdVal;
  srcAttr: ZAttribute;
};

type Cmd = CmdAttr | CmdDict | CmdGroup | CmdVal;

export const copyObject = async (
  srcObjId: number,
  values: CopyParams,
): Promise<ZObjectItem> => {
  const srcObj = await loadObject(srcObjId);
  const srcStates = await loadObjectStates(srcObjId);
  const srcRolesMap = await makeRolesMap(srcObjId, srcStates);
  const templatesMap = await loadNotifyTemplates(srcObjId);
  const attrForCopy = new Set(values.attrs);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: _id, attributes, groups, ...rest } = srcObj;
  const newObj = await createObject({
    ...rest,
    name: values.name,
    attributes: [],
    groups: [],
  });

  await Promise.all([
    srcStates
      .filter(({ id }) => id !== 1)
      .map(({ name }) => createObjectState(newObj.id, name)),
    saveObjectRolesMap(newObj.id, srcRolesMap),
    saveNotifyTemplates(newObj.id, templatesMap),
  ]);

  const cmdObj: CmdObj = { cmd: "obj", srcObjId, dstObjId: newObj.id };
  const cmdList: Cmd[] = await scanGroups(srcObj.groups, attrForCopy, cmdObj);
  for (let i = cmdList.length - 1; i >= 0; i--) {
    const curCmd = cmdList[i];
    if (curCmd?.cmd === "attr") {
      curCmd.owner.used = true;
    } else if (
      (curCmd?.cmd === "group" ||
        curCmd?.cmd === "dict" ||
        curCmd?.cmd === "val") &&
      curCmd.used
    ) {
      curCmd.owner.used = true;
    }
    if (curCmd?.cmd === "dict" && curCmd.used) {
      // Если нужно копировать условную группу, то нужно копировать и тот атрибут, от которого зависит условие
      const { attributeId } = curCmd.srcGroup;
      if (attributeId) attrForCopy.add(attributeId);
    }
  }
  const existsAttrsMap: Record<number, number> = {};
  const restrictionsMap: Record<number, ZRestriction> = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const srcAttr of srcObj.attributes) {
    if (attrForCopy.has(srcAttr.id)) {
      await execAttr(
        {
          cmd: "attr",
          owner: cmdObj,
          srcAttr,
        },
        existsAttrsMap,
        srcStates,
        restrictionsMap,
      );
    }
  }
  await executeCommands(cmdList, existsAttrsMap, srcStates);

  const dstObj = await loadObject(newObj.id);
  await copyRetrictions(srcObj, dstObj, restrictionsMap);

  return dstObj;
};

const scanGroups = async (
  groups: ZGroup[] | null | undefined,
  attrForCopy: Set<number>,
  ownerCmd: CmdGroup | CmdObj | CmdVal,
): Promise<Cmd[]> => {
  const cmdRes: Cmd[][] = [];
  if (groups) {
    // eslint-disable-next-line no-restricted-syntax
    for (const group of groups) {
      if (group.groupType.name === GroupType.Mnemonic) {
        if (!(ownerCmd.cmd === "obj" && group.valueId)) {
          cmdRes.push(await scanGroup(group, attrForCopy, ownerCmd));
        }
      } else if (group.groupType.name === GroupType.ByDictionary) {
        cmdRes.push(await scanDictGroup(group, attrForCopy, ownerCmd));
      }
    }
  }
  return cmdRes.flatMap((c) => c);
};

const scanAttrs = async (
  attrs: ZAttribute[] | null | undefined,
  attrsForCopy: Set<number>,
  owner: CmdGroup | CmdVal,
): Promise<CmdAttr[]> =>
  (attrs ?? [])
    .filter(({ id }) => attrsForCopy.has(id))
    .map((srcAttr) => ({
      cmd: "attr",
      owner,
      srcAttr,
    }));

const scanGroup = async (
  group: ZGroup,
  attrsForCopy: Set<number>,
  owner: CmdGroup | CmdObj | CmdVal,
): Promise<Cmd[]> => {
  const cmd: Cmd = { cmd: "group", owner, srcGroup: group };
  const attrs = await scanAttrs(group.attributes, attrsForCopy, cmd);
  const groups = await scanGroups(group.groups, attrsForCopy, cmd);
  return [cmd, ...attrs, ...groups];
};

const scanDictGroup = async (
  srcGroup: ZGroup,
  attrsForCopy: Set<number>,
  owner: CmdGroup | CmdObj | CmdVal,
): Promise<Cmd[]> => {
  const cmd: CmdDict = { cmd: "dict", owner, srcGroup };
  const res: Cmd[][] = [[cmd]];
  const { attributeId } = srcGroup;
  if (attributeId) {
    const values = await loadFromAttr(attributeId);
    // eslint-disable-next-line no-restricted-syntax
    for (const val of values) {
      const cmdVal: CmdVal = { cmd: "val", val, owner: cmd };
      res.push([cmdVal]);
      const things = await loadValueThings(srcGroup.id, val.id);
      res.push(await scanAttrs(things.attributes, attrsForCopy, cmdVal));
      res.push(await scanGroups(things.groups, attrsForCopy, cmdVal));
    }
  }
  return res.flatMap((c) => c);
};

const executeCommands = async (
  cmdList: Cmd[],
  existsAttrsMap: Record<number, number>,
  srcStates: ZObjState[],
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const cmd of cmdList) {
    switch (cmd.cmd) {
      case "group":
        await execGroup(cmd, existsAttrsMap);
        break;
      case "dict":
        await execGroup(cmd, existsAttrsMap);
        break;
      case "attr":
        await execAttr(cmd, existsAttrsMap, srcStates);
        break;
      default:
        break;
    }
  }
};
const execGroup = async (
  cmd: CmdDict | CmdGroup,
  existsAttrsMap: Record<number, number>,
) => {
  if (!cmd.used) return;
  const { owner } = cmd;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, attributes, groups, ...pureGroup } = cmd.srcGroup;
  const { attributeId } = pureGroup;
  if (attributeId) {
    // Нужно перейти на тот атрибут, который в новом объекте
    const newId = existsAttrsMap[attributeId];
    if (!newId) throw Error(`Not found clone of attr ${attributeId}`);
    pureGroup.attributeId = newId;
  }
  let res: ZGroup;
  if (owner.cmd === "obj") {
    if (!owner.dstObjId) throw Error("Expected dst object for group");
    res = await createGroupInObj(owner.dstObjId, pureGroup);
  } else if (owner.cmd === "val") {
    const { dstGroup } = owner.owner;
    if (!dstGroup) throw Error(`Expected dst group for ${owner.val.name}`);
    res = await createGroupInValue(dstGroup.id, owner.val.id, pureGroup);
  } else {
    if (!owner.dstGroup) throw Error("Expected dst group for group");
    res = await createGroupInGroup(owner.dstGroup.id, pureGroup);
  }
  cmd.dstGroup = res;
};

const execAttr = async (
  cmd: CmdAttr,
  existsAttrsMap: Record<number, number>,
  srcStates: ZObjState[],
  restrictionsMap?: Record<number, ZRestriction>,
) => {
  const { owner } = cmd;
  const { id: srcId, ...body } = cmd.srcAttr;
  const permissions = await Promise.all(
    srcStates.map(({ id: stateId }) =>
      loadAttributePermissions(srcId, stateId),
    ),
  );
  const restrictions = await loadRestrictions(srcId);
  let dstAttr: ZAttribute | undefined;
  if (owner.cmd === "obj") {
    if (!owner.dstObjId) throw Error("Expected dst object for attribute");
    dstAttr = await createAttributeInObject(owner.dstObjId, body);
  } else if (owner.cmd === "group") {
    if (!owner.dstGroup) throw Error("Expected dst group for attribute");
    dstAttr = await createAttrInGroup(owner.dstGroup.id, body);
  } else {
    const { dstGroup } = owner.owner;
    if (!dstGroup) throw Error(`Expected dst group for ${owner.val.name}`);
    const existsAttrId = existsAttrsMap[srcId];
    if (existsAttrId) {
      await apiAddExistingAttr(dstGroup.id, owner.val.id, existsAttrId);
    } else {
      dstAttr = await createAttrInValue(dstGroup.id, owner.val.id, body);
    }
  }
  if (dstAttr) {
    const dstAttrId = dstAttr.id;
    existsAttrsMap[srcId] = dstAttrId;
    await Promise.all(
      srcStates.map(({ id: stateId }, i) =>
        saveAttributePermissions(dstAttrId, stateId, permissions[i]!),
      ),
    );
    if (restrictions && restrictionsMap) {
      restrictionsMap[dstAttrId] = restrictions;
    }
  }
};

const copyRetrictions = async (
  srcObj: ZObjectItem,
  dstObj: ZObjectItem,
  restrictionsMap: Record<number, ZRestriction>,
): Promise<void> => {
  const srcAllAttrs = getAttributesFromObject(srcObj);
  const dstAllAttrs = getAttributesFromObject(dstObj);

  const dstAttrNamesMap = Object.values(dstAllAttrs).reduce<
    Record<string, number>
  >((map, { id, name }) => {
    map[name] = id;
    return map;
  }, {});

  await Promise.all(
    Object.entries(restrictionsMap).map(([attrId, restrictions]) => {
      const srcRestid = restrictions.restrictionAttributeId;
      const srcAttrName = srcAllAttrs[srcRestid]?.name;
      if (!srcAttrName)
        throw new Error(
          `Cannot find attribute with id=${srcRestid} in srcObject`,
        );

      const dstAttrId = dstAttrNamesMap[srcAttrName];
      if (!dstAttrId)
        throw new Error(
          `Cannot find attribute with name=${srcAttrName} in dstObject`,
        );

      const dstRestrictions = {
        ...restrictions,
        restrictionAttributeId: dstAttrId,
      };
      return saveRestrictions(Number(attrId), dstRestrictions);
    }),
  );
};
