// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__tableBox--jJ6Xj {
  flex: 1;
  overflow: hidden;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__tableHeader--x7mcX {
  display: grid;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cellTop--Kxc3J {
  border-top: thin solid #E1E3E6;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cellLeft--b3LG5 {
  border-left: thin solid #E1E3E6;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cell--SYSjk {
  border-right: thin solid #E1E3E6;
  border-bottom: thin solid #E1E3E6;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cellRow--KsBkm {
  color: #343A41;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  overflow: hidden;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__baseSizeCell--vXeDc {
  background-color: #EFF5FF;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cellHd--Irrg8 {
  color: #767C84;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cellCentered--vxAt1 {
  text-align: center;
  padding-top: 4px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__pad--ZouZH {
  padding: 8px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__titleBox--TL_L7 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__titleText--lyP2v {
  padding: 8px;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/MChartTable/MChartTable.module.less","webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/MChartTable/MCTitle.module.less"],"names":[],"mappings":"AAEA;EACI,OAAA;EACA,gBAAA;ACDJ;ADIA;EACI,aAAA;ACFJ;ADKA;EACI,8BAAA;ACHJ;ADKA;EACI,+BAAA;ACHJ;ADMA;EACI,gCAAA;EACA,iCAAA;ACJJ;ADOA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;ACLJ;ADOA;EACI,yBAAA;ACLJ;ADQA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;ACNJ;ADQA;EACI,kBAAA;EACA,gBAAA;ACNJ;ADQA;EACI,YAAA;ACNJ;AAvCA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;AAyCJ;AAvCA;EDuCI,YAAA;ECrCA,OAAA;AAyCJ","sourcesContent":["@border: thin solid #E1E3E6;\n\n.tableBox {\n    flex: 1;\n    overflow: hidden;\n}\n\n.tableHeader {\n    display: grid;\n}\n\n.cellTop {\n    border-top: @border;\n}\n.cellLeft {\n    border-left: @border;\n}\n\n.cell {\n    border-right: @border;\n    border-bottom: @border;    \n}\n\n.cellRow {\n    color: #343A41;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n    overflow: hidden;\n}\n.baseSizeCell {\n    background-color: #EFF5FF;\n}\n\n.cellHd {\n    color: #767C84;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;    \n}\n.cellCentered {\n    text-align: center;\n    padding-top: 4px;\n}\n.pad {\n    padding: 8px;\n}","@import \"./MChartTable.module.less\";\n\n.titleBox {\n    display: flex;\n    flex-direction: row;\n    gap: 4px;\n    align-items: center;\n}\n.titleText {\n    .pad;\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__tableBox--jJ6Xj`,
	"tableHeader": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__tableHeader--x7mcX`,
	"cellTop": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cellTop--Kxc3J`,
	"cellLeft": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cellLeft--b3LG5`,
	"cell": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cell--SYSjk`,
	"cellRow": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cellRow--KsBkm`,
	"baseSizeCell": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__baseSizeCell--vXeDc`,
	"cellHd": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cellHd--Irrg8`,
	"cellCentered": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__cellCentered--vxAt1`,
	"pad": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__pad--ZouZH`,
	"titleBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__titleBox--TL_L7`,
	"titleText": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-MChartTable-MCTitle-module__titleText--lyP2v`
};
export default ___CSS_LOADER_EXPORT___;
