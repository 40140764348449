/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react";
import { classNames } from "src/common/classNames";
import { Tag } from "antd";
import { ZRCAttachment } from "../../../../rocketChat/rocketChat.types";
import { defineAttachment } from "../defineAttachment/defineAttachment";
import styles from "./ChatMsg.module.less";

const parseMsg = (msg: string) => {
  const isMention = (str: string) => str[0] === "@";
  const isQuote = (str: string) => str.includes("[ ]");
  const getMsgFromQuote = (str: string) => str.split("\n").pop() || "";

  const actualMsg = isQuote(msg) ? getMsgFromQuote(msg) : msg;
  /**
   * парсинг упоминаний типа "@mention" :
   * https://stackoverflow.com/questions/15265605/how-to-pull-mentions-out-of-strings-like-twitter-in-javascript
   */
  const withRawMentions = actualMsg.split(/(\B@[a-z0-9_-]+)/g);
  const withMentions = withRawMentions.map((part, i) =>
    isMention(part) ? (
      <Tag key={i}>{part}</Tag>
    ) : (
      <Fragment key={i}>{part}</Fragment>
    ),
  );
  return withMentions;
};

type PropsChatMsg = {
  text: string;
  attachments: ZRCAttachment[];
  className?: string;
};

export const ChatMsg: React.FC<PropsChatMsg> = ({
  attachments,
  text,
  className,
}) => (
  <div className={classNames([styles.box, className])}>
    {text && <div>{parseMsg(text)}</div>}
    {attachments?.map((item, i) => (
      <React.Fragment key={i}>{defineAttachment(item)}</React.Fragment>
    ))}
  </div>
);

ChatMsg.defaultProps = {
  className: undefined,
};
