import { AttrTypeName, makeDictNameById } from "src/types/AttrType";
import { ZObjectItem } from "src/types/ZObjectItem";
import { z } from "zod";
import { ZAttribute } from "src/types/ZAttribute";
import { FormRule } from "antd";
import { getIdLabels } from "src/references/getIdNames";
import { validateEntityFormula } from "src/pages/EntityCardPage/apiEntityCard";
import { getObjectAttsFlat } from "src/pages/EntityCardPage/getObjectAttsFlat";
import { getEditorInfo } from "../getEditorInfo";

export const zAttrFormula = z.object({
  editor: z.literal("Formula"),
  formula: z.string(),
  precision: z.number().nullable().optional(),
  addonBefore: z.string().nullable().optional(),
  addonAfter: z.string().nullable().optional(),
});
export type ZAttrFormula = z.infer<typeof zAttrFormula>;

export const makeFormulaProps = (src: ZAttrFormula) => ({
  precision: src.precision ?? undefined,
  addonBefore: src.addonBefore ?? undefined,
  addonAfter: src.addonAfter ?? undefined,
});

export const attrFormulaPefixView = "@";
export const attrFormulaPefixBuss = "a";

export const makeFormulaAttPartView = (attr: ZAttribute) =>
  `${attrFormulaPefixView}${attr.name}`;
export const makeFormulaAttPartBuss = (attr: ZAttribute) =>
  `${attrFormulaPefixBuss}${attr.id}`;
export const isFormulaAttrPartBuss = (part: string) =>
  part[0] === attrFormulaPefixBuss && part.length > 1;
export const parseFormulaPartsBuss = (template: string) =>
  template.split(new RegExp(`(${attrFormulaPefixBuss}\\d*)`));
const getAttrIdFromAttrPart = (part: string) =>
  part.replaceAll(attrFormulaPefixBuss, "");

export const isAttrAllowedInFormula = (
  attr: ZAttribute,
  curAttrId: number,
  attrTypesDict: Record<number, string>,
) => {
  const { valueType } = attr;
  if (curAttrId === attr.id) return false;
  return (
    attrTypesDict[valueType] === AttrTypeName.int ||
    attrTypesDict[valueType] === AttrTypeName.number ||
    attrTypesDict[valueType] === AttrTypeName.formula
  );
};

const hasWrongAttrPartView = (template: string, atts: ZAttribute[]) => {
  let formula = template;
  atts
    .map(makeFormulaAttPartView)
    .sort((a, b) => b.length - a.length)
    .forEach((part) => {
      formula = formula.replaceAll(part, "");
    });
  return (
    formula[formula.length - 1] !== attrFormulaPefixView &&
    formula.includes(attrFormulaPefixView)
  );
};

export const validateFormula = (
  objectId: number,
  curAttrId: number,
  attrList: ZAttribute[],
): FormRule => ({
  validator: async (rule, value) => {
    try {
      const attrTypesDict = makeDictNameById(
        await getIdLabels("attrType", "attrType"),
      );
      const allowedAtts = attrList.filter((attr) =>
        isAttrAllowedInFormula(attr, curAttrId, attrTypesDict),
      );
      const bussinesValue = await transformFormula4Save(value, attrList);
      await validateEntityFormula(bussinesValue, objectId);
      if (hasWrongAttrPartView(value, allowedAtts)) {
        return Promise.reject(Error("Ошибка в формуле"));
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(Error("Ошибка в формуле"));
    }
  },
});

export const getFormulaDepAttsIds = (
  object: ZObjectItem,
  attrTypesMap: Record<number, string>,
) => {
  const objAtts = getObjectAttsFlat(object);
  const formulaAtts = objAtts.filter(
    ({ valueType }) => attrTypesMap[valueType] === AttrTypeName.formula,
  );
  const depAtts = formulaAtts
    .map(({ viewStyles }) => {
      const editor = getEditorInfo(viewStyles);
      if (editor?.component?.editor === "Formula") {
        return parseFormulaPartsBuss(editor.component.formula)
          .filter(isFormulaAttrPartBuss)
          .map(getAttrIdFromAttrPart);
      }
      return [];
    })
    .flat();
  return depAtts;
};

const transformEdAttVals = async (
  search: (attr: ZAttribute) => string,
  replace: (attr: ZAttribute) => string,
  formula: string,
  attrList: ZAttribute[],
) => {
  let newFormula = formula;
  attrList
    .sort((a, b) => b.name.length - a.name.length)
    .forEach((attr) => {
      newFormula = newFormula.replaceAll(search(attr), replace(attr));
    });
  return newFormula;
};

export const transformFormula4Save = async (
  formula: string,
  attrList: ZAttribute[],
) =>
  transformEdAttVals(
    makeFormulaAttPartView,
    makeFormulaAttPartBuss,
    formula,
    attrList,
  );

export const transformFormula4Show = async (
  formula: string,
  attrList: ZAttribute[],
) =>
  transformEdAttVals(
    makeFormulaAttPartBuss,
    makeFormulaAttPartView,
    formula,
    attrList,
  );
