import { rest } from "src/common/rest";
import { apiMeasurementChart, apiObjUrl } from "src/common/apiUrl";
import { zEntity, ZEntity } from "src/types/ZEntity";
import { z } from "zod";
import { zIdName } from "src/types/ZIdName";
import {
  NewMeasurementChartEntityData,
  zMeasurementChart,
  ZMeasurementChart,
} from "./ZMeasurementChart";
import { zMesChartSettings, ZMesChartSettings } from "./ZMesChartSettings";
import { NumOption } from "./MChartEntity/EditMCEntity/MChartTable/PointDict";

export const loadBaseSizes = async (
  servObjId: number,
  sizeId: number,
): Promise<NumOption[]> => {
  const resp = await rest.get(
    apiMeasurementChart(`/copy/${servObjId}/${sizeId}`),
  );
  const list = zIdName.array().parse(resp.data);
  return list.map(({ id, name }) => ({ value: id, label: name }));
};

export const loadEntitiesAsRef = async (
  objectId: number,
): Promise<ZEntity[]> => {
  const resp = await rest.post(
    apiObjUrl(`/entities/search`),
    { objectId },
    { params: { page: 0, size: 1000 } },
  );
  const zResp = z.object({
    content: zEntity.array(),
  });
  return zResp.parse(resp.data).content;
};

export const loadMesChartSettings = async (servObjId: number) => {
  const resp = await rest.get(apiMeasurementChart(`/settings/${servObjId}`));
  return zMesChartSettings.parse(resp.data);
};

export const saveMesChartSettings = async (
  servObjId: number,
  data: ZMesChartSettings,
) => {
  await rest.put(apiMeasurementChart(`/settings/${servObjId}`), data);
};

export const loadMeasurementChart = async (
  modelId: number,
): Promise<ZMeasurementChart> => {
  const resp = await rest.get(apiMeasurementChart(`/entity/${modelId}`));
  return zMeasurementChart.parse(resp.data);
};

export const saveMeasurementChart = async (
  modelId: number,
  data: ZMeasurementChart,
): Promise<ZMeasurementChart> => {
  const resp = await rest.put(apiMeasurementChart(`/entity/${modelId}`), data);
  return zMeasurementChart.parse(resp.data);
};

export const createMeasurementChart = async (
  data: NewMeasurementChartEntityData,
): Promise<ZMeasurementChart> => {
  const resp = await rest.post(apiMeasurementChart("/entity"), data);
  return zMeasurementChart.parse(resp.data);
};

export const deleteMeasurementChart = async (
  modelId: number,
): Promise<void> => {
  await rest.delete(apiMeasurementChart(`/entity/${modelId}`));
};
