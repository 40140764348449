import { ReactNode } from "react";
import i18, { t } from "i18next";
import axios from "axios";
import { z } from "zod";
import { makeAutoObservable } from "mobx";
import { ZUserInfo, zUserInfo } from "src/types/ZUserInfo";
import { RemoteData } from "src/common/RemoteData";
import enRes from "src/lang/en";
import ruRes from "src/lang/ru";
import { detectLang } from "./common/detectLang";
import { rest } from "./common/rest";
import { apiAuthUrl, apiConfigUrl, apiI18nUrl } from "./common/apiUrl";
import { ZLanguageProps, zLanguageProps } from "./types/ZLanguageProps";
import { onError } from "./common/onError";
import { makeDictionary } from "./common/makeDictionary";
import { notificationsStore } from "./common/notificationsStore";
import { zSsoUrl } from "./types/ZSsoUrl";
import { ZExtraSettings, zExtraSettings } from "./types/ZExtraSettings";

export type OverlayPage = "login" | "passRestore";

// const urlRefresh = "/refresh";
// const urlCurrent = "/current";
// const urlLogin = "/login";
// const urlLogout = "/logout";

const keyAccessToken = "accessToken";
const keyRefreshToken = "refreshToken";

const zLogin = z.object({
  refreshToken: z.string(),
  token: z.string(),
});
type ZLogin = z.infer<typeof zLogin>;

export const setToken = (key: string, value?: string) => {
  try {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

const setAllTokens = (data: ZLogin | undefined) => {
  if (data) {
    setToken(keyRefreshToken, data.refreshToken);
    setToken(keyAccessToken, data.token);
  } else {
    setToken(keyRefreshToken, undefined);
    setToken(keyAccessToken, undefined);
  }
};

const setRefreshToken = (token: string) => setToken(keyRefreshToken, token);
const getRefreshToken = (): string =>
  localStorage.getItem(keyRefreshToken) || "";
export const getAccessToken = (): string =>
  localStorage.getItem(keyAccessToken) || "";

let authWait: Promise<Response> | null = null;

export type AuthTaskResult = { status: number };

export type Breadcrumb = {
  title: string;
  href?: string;
};

export const authTask = async <R extends AuthTaskResult>(
  task: () => Promise<R>,
): Promise<R> => {
  let resp: R;
  try {
    resp = await task();
  } catch (e) {
    if ("response" in e) {
      resp = e.response;
      if (resp.status !== 401) throw e;
    } else {
      throw e;
    }
  }
  if (resp.status === 401) {
    authWait =
      authWait ||
      fetch(apiAuthUrl("/refresh"), {
        method: "post",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ refresh: getRefreshToken() }),
      });
    const resp1 = await authWait;
    authWait = null;
    if (resp1.status !== 200) {
      const error = new Error(t("Authorization required"));
      if (appStore.userData.status !== "error") {
        appStore.setUserData({ status: "error", error });
      }
      appStore.setOverlayType("login");
      throw error;
    }
    /**
     * по какой-то причине токены не обновлялись после refresh
     * что приводило к ошибке по истечению срока действия refreshToken
     */
    const resp1Data = await resp1.clone().json();
    const refreshResult = zLogin.parse(resp1Data);
    setAllTokens(refreshResult);
    resp = await task();
  }
  return resp;
};

export const appStore = makeAutoObservable({
  userData: {} as RemoteData<ZUserInfo>,
  setUserData(data: RemoteData<ZUserInfo>) {
    this.userData = data;
    this.setCurrentCompanyId(0); // TODO: Пока нет такого поля...
  },
  get userInfo(): ZUserInfo {
    if (this.userData.status !== "ready") throw Error("Empty user data");
    return this.userData.result;
  },
  currentCompanyId: 0,
  setCurrentCompanyId(id: number) {
    this.currentCompanyId = id;
  },

  overlayType: null as OverlayPage | null,
  setOverlayType(newType: OverlayPage | null) {
    this.overlayType = newType;
  },

  contentLanguages: [] as ZLanguageProps[],
  setContentLanguages(list: ZLanguageProps[]) {
    this.contentLanguages = list;
  },
  get contentLangMap(): Record<string, ZLanguageProps> {
    return makeDictionary(this.contentLanguages, ({ code }) => code);
  },

  async init() {
    this.setUserData({ status: "wait" });

    try {
      const resp = await authTask(() => fetch(apiAuthUrl("/current")));
      if (resp.status !== 200) throw Error(`Status ${resp.status}`);
      const rawData = await resp.json();
      const data = zUserInfo.parse(rawData);
      this.setUserData({ status: "ready", result: data });
      const { refreshToken } = data;
      if (refreshToken) setRefreshToken(refreshToken);
      notificationsStore.init();
    } catch (error) {
      this.setUserData({ status: "error", error });
    }

    // Список языков контента.
    // Будем считать, что он не является жизненно необходимым для работы приложения
    rest
      .get(apiI18nUrl("/languages"))
      .then((langResp) =>
        this.setContentLanguages(zLanguageProps.array().parse(langResp.data)),
      )
      .catch(onError);

    this.loadExtraSettings();
  },

  async login(login: string, password: string) {
    try {
      this.setUserData({ status: "wait" });
      const respLogin = await axios.post(apiAuthUrl("/login"), {
        login,
        password,
      });
      const loginResult = zLogin.parse(respLogin.data);
      setAllTokens(loginResult);
      const respCurrent = await axios.get(apiAuthUrl("/current"));
      const currentResult: ZUserInfo = zUserInfo.parse(respCurrent.data);
      this.setUserData({ status: "ready", result: currentResult });
      this.setOverlayType(null);
      notificationsStore.init();
    } catch (e) {
      const error =
        e.response?.status === 401
          ? Error(t("There is no user with the specified data"))
          : e;
      this.setUserData({ status: "error", error });
    }
  },

  async logout() {
    axios
      .post(apiAuthUrl("/logout"), { refresh: getRefreshToken() })
      .catch(onError); // Не ждём ответа, т.к. он не нужен.
    setAllTokens(undefined);
    this.setOverlayType("login");
    notificationsStore.init();
  },

  pageTitle: "" as ReactNode,
  setPageTitle(title: ReactNode) {
    this.pageTitle = title;
  },

  breadcrumb: [] as Breadcrumb[],
  setBreadcrumb(breadcrumb: Breadcrumb[]) {
    this.breadcrumb = breadcrumb;
  },

  setDocumentTitle(documentTitle: string) {
    document.title = documentTitle;
  },

  get curLang(): string {
    return i18.language;
  },

  get isNotAuth(): boolean {
    return this.userData.status !== "ready";
  },

  extraSettings: null as ZExtraSettings | null,
  setExtraSettings(settings: ZExtraSettings) {
    this.extraSettings = settings;
  },

  ssoAuthUrl: null as string | null,
  setSsoAuthUrl(url: string | null) {
    this.ssoAuthUrl = url;
  },

  loadExtraSettings() {
    rest
      .get(apiConfigUrl("/ui-settings"))
      .then((extraSettingsResp) => {
        this.setExtraSettings(zExtraSettings.parse(extraSettingsResp.data));
      })
      .catch(onError);
  },

  loadSsoUrl() {
    const { extraSettings, ssoAuthUrl } = this;
    if (!extraSettings?.sso) return;
    if (ssoAuthUrl) this.setSsoAuthUrl(null);
    rest
      .get(apiAuthUrl("/external-auth-link"))
      .then((urlResp) =>
        this.setSsoAuthUrl(zSsoUrl.parse(urlResp.data).externalAuthUrl),
      )
      .catch(onError);
  },
});

const curLang = detectLang();

i18.init({
  lng: curLang,
  debug: window.location.href.startsWith("http://localhost"),
  interpolation: {
    escape: (x) => x,
  },
  resources: {
    en: enRes,
    ru: ruRes,
  },
});
