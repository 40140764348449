import React, { useMemo } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import { observer } from "mobx-react-lite";
import { AttrLinkedListView } from "./AttrLinkedListView";
import { createAttrLinkedStore } from "./AttrLinkedStore";

/**
 * Выводит значения связанного атрибута. Согласно договорённости с Максом,
 * пока что выводим "сырые" значения простых типов без учета их настроек.
 * Остальная доработка будет в след. спринте
 */
type PropsAttrLinked = {
  attr: ZAttribute;
  entityId?: number;
};

export const AttrLinked: React.FC<PropsAttrLinked> = observer(
  ({ attr, entityId }) => {
    const store = useMemo(() => {
      if (entityId) return createAttrLinkedStore(entityId, attr);
      return null;
    }, [entityId]);

    if (!store || !store.linkedAttrData) return null;
    const { attribute, values } = store.linkedAttrData;

    return (
      <AttrLinkedListView
        attrData={attr}
        linkedAttrData={attribute}
        values={values}
      />
    );
  },
);
AttrLinked.defaultProps = { entityId: undefined };
