import React from "react";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { t } from "i18next";
import styles from "./SummaryFooter.module.less";
import { getColumnWidth } from "../../../pages/EntityFiltersPage/AsyncTableView/templates/getColumnWidths";
import { ChildEntitiesStore } from "../ChildEntitiesStore";

interface PropsSummaryFooter {
  store: ChildEntitiesStore;
}

export const SummaryFooter: React.FC<PropsSummaryFooter> = observer(
  ({ store, store: { tableStore } }) => {
    if (!tableStore || tableStore?.rows.length === 0) return null;
    return (
      <div className={styles.tableFooter}>
        <div
          className={classNames([styles.footerItem, styles.footerItemTitle])}
        >
          {t("Total")}
        </div>
        {tableStore.finalColumns.map((column) => (
          <div
            className={styles.footerItem}
            key={column.key}
            style={getColumnWidth(tableStore.columnWidths, column.key)}
          >
            <div className={styles.columnFooterContent}>
              {store.summaryValues?.[column.key] ?? ""}
            </div>
          </div>
        ))}
      </div>
    );
  },
);
