import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { optionalLangParams } from "src/lang/langHdr";
import { zChildAttribute, ZChildAttribute } from "./ZChildAttribute";

export const loadChildAttributes = async (
  objId: number,
  options: {
    translate?: boolean;
  } = {},
): Promise<ZChildAttribute[]> => {
  const { translate } = options;
  const res = await rest.get(
    apiObjUrl(`/objects/${objId}/child-attributes`),
    optionalLangParams(translate),
  );
  return zChildAttribute.array().parse(res.data);
};

export const copyEntity = async (entityId: number, childAtts?: number[]) => {
  await rest.post(apiObjUrl("/entities/copy"), {
    entityId,
    attributes: childAtts,
  });
};
