// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-dropdown-menu-item:has(.src-pages-EntityFiltersPage-EntityList-ChangeStateBulkButton-ChangeStateBulkButton-module__button--BeGu1) {
  padding: 0 !important;
  background: none !important;
}
.ant-dropdown-menu-item:has(.src-pages-EntityFiltersPage-EntityList-ChangeStateBulkButton-ChangeStateBulkButton-module__button--BeGu1) .src-pages-EntityFiltersPage-EntityList-ChangeStateBulkButton-ChangeStateBulkButton-module__button--BeGu1 {
  border: none;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  align-items: center;
  padding: 5px 12px;
  width: 100%;
  font-size: inherit;
  height: auto;
}
.ant-dropdown-menu-item:has(.src-pages-EntityFiltersPage-EntityList-ChangeStateBulkButton-ChangeStateBulkButton-module__button--BeGu1) .src-pages-EntityFiltersPage-EntityList-ChangeStateBulkButton-ChangeStateBulkButton-module__button--BeGu1:hover:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.04);
  color: inherit !important;
}
.src-pages-EntityFiltersPage-EntityList-ChangeStateBulkButton-ChangeStateBulkButton-module__icon--qQqxQ svg {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/EntityList/ChangeStateBulkButton/ChangeStateBulkButton.module.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,2BAAA;AACF;AAHA;EAKI,YAAA;EACA,6BAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AACJ;AAdA;EAiBI,qCAAA;EACA,yBAAA;AAAJ;AAGA;EAEI,eAAA;AAFJ","sourcesContent":[":global(.ant-dropdown-menu-item):has(.button) {\n  padding: 0 !important;\n  background: none !important;\n\n  .button {\n    border: none;\n    background-color: transparent;\n    box-shadow: none;\n    display: flex;\n    align-items: center;\n    padding: 5px 12px;\n    width: 100%;\n    font-size: inherit;\n    height: auto;\n  }\n\n  .button:hover:not(:disabled) {\n    background-color: rgba(0, 0, 0, 0.04);\n    color: inherit !important;\n  }\n}\n.icon {\n  svg {\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `src-pages-EntityFiltersPage-EntityList-ChangeStateBulkButton-ChangeStateBulkButton-module__button--BeGu1`,
	"icon": `src-pages-EntityFiltersPage-EntityList-ChangeStateBulkButton-ChangeStateBulkButton-module__icon--qQqxQ`
};
export default ___CSS_LOADER_EXPORT___;
