import * as React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import { TechpackSettings } from "./TechpackSettings";
import { loadTechpackSettings, saveTechpackSettings } from "./apiTechpack";
import { zTechpackSettings } from "./TechpackTypes";
import { TechpackControl } from "./TechpackControl/TechpackControl";

export const bsTechpack: BusinessServiceDescriptor = {
  key: "techpack",
  viewer: (info, data) => <TechpackControl info={info} entityData={data} />,
  renderSettings: (_info, store) => <TechpackSettings store={store} />,
  loadSettings: async (info) => loadTechpackSettings(info.id),
  async saveSettings(info, values) {
    const settings = zTechpackSettings.parse(values);
    await saveTechpackSettings(info.id, settings);
  },
};
