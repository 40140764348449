import { makeAutoObservable } from "mobx";
import { getEntityLinkedValues } from "src/pages/EntityCardPage/apiEntityCard";
import { ZAttribute, ZAttrLinkedData } from "src/types/ZAttribute";

export class AttrLinkedStore {
  constructor(entityId: number, attr: ZAttribute) {
    getEntityLinkedValues([{ entityId, linkAttributeId: attr.id }]).then(
      (data) => {
        const need = data.find((d) => d.linkAttributeId === attr.id);
        if (need) this.setLinkedAttrData(need);
      },
    );
    makeAutoObservable(this);
  }

  linkedAttrData: ZAttrLinkedData | null = null;

  setLinkedAttrData(data: ZAttrLinkedData) {
    this.linkedAttrData = data;
  }
}

export const createAttrLinkedStore = (entityId: number, attr: ZAttribute) =>
  new AttrLinkedStore(entityId, attr);
