/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { ObjectServiceExt } from "src/businessServices/businessServises.types";
import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";
import { Tabs, TabsProps, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { appStore } from "src/appStore";
import styles from "./ChatListPage.module.less";
import { ChatListPageStore } from "./ChatListPageStore";
import { ChatForm } from "./ChatForm/ChatForm";
import { NewChatButton } from "./NewChatButton/NewChatButton";
import { ChatSettingsMenu } from "./ChatForm/chatSettings/ChatSettingsMenu";
import { ZChatInfo } from "./bsChat.types";

const isChatOwner = (userId: string) =>
  // eslint-disable-next-line no-underscore-dangle
  appStore.userData.status === "ready" &&
  appStore.userData.result.id === userId;

type PropsChatListPage = { info: ObjectServiceExt; data: EntityCardData };

export const ChatListPage: React.FC<PropsChatListPage> = observer(
  ({ data, info }) => {
    const { entity } = data;
    const pageStore = useMemo(() => new ChatListPageStore(), []);
    const { activeChatId: activeChatid } = pageStore;

    useEffect(() => {
      if (entity) pageStore.init(entity.id, info.name, data);
      return () => pageStore.destroy();
    }, []);

    const isDefaultchat = (ci: ZChatInfo | null) => ci?.name === info.name;

    // сортируем, чтобы дефолтный чат выводился первым
    const sortedChats = pageStore.chats
      .slice()
      .sort((chat) => (isDefaultchat(chat.chatInfo) ? -1 : 1));

    const items: TabsProps["items"] = sortedChats.map((item) => ({
      key: item.chatId,
      label: item.chatName,
    }));

    const chatStore = sortedChats.find((c) => c.chatId === activeChatid);

    return (
      <div className={styles.layout}>
        <div className={styles.sider}>
          <Typography.Text strong>Список чатов</Typography.Text>
          <Tabs
            tabPosition="left"
            items={items}
            onChange={(id) => pageStore.setActiveChatId(id)}
            activeKey={pageStore.activeChatId ?? undefined}
          />
          <NewChatButton cardData={data} pageStore={pageStore} />
        </div>
        <div className={styles.content}>
          {chatStore?.chatInfo && (
            <ChatForm
              key={chatStore.chatId}
              store={chatStore}
              pageStore={pageStore}
              headerExtra={
                !isDefaultchat(chatStore?.chatInfo) &&
                isChatOwner(chatStore.chatInfo.ownerUserId) && (
                  <ChatSettingsMenu
                    cardData={data}
                    chatInfo={chatStore.chatInfo}
                    pageStore={pageStore}
                  />
                )
              }
            />
          )}
        </div>
      </div>
    );
  },
);
