import { z } from "zod";

export const zMeasurementChartEntity = z.object({
  mcEntityId: z.number(),
  entityId: z.number(),
  mcServiceId: z.number(),
  sizeLineEntityId: z.number(),
  baseSizeEntityId: z.number(),
  mcName: z.string(),
  comment: z.string(),
});
export type ZMeasurementChartEntity = z.infer<typeof zMeasurementChartEntity>;

export type NewMeasurementChartEntityData = Omit<
  ZMeasurementChartEntity,
  "mcEntityId"
>;

const zMCPointName = z.object({
  id: z.number(),
  name: z.string().nullable(),
});

const zMCVersion = z.object({
  id: z.number(),
  name: z.string(),
});
export type ZMCVersion = z.infer<typeof zMCVersion>;

const zMCPointValue = z.object({
  id: z.number(),
  version: zMCVersion,
  value: z.number().nullable(),
});
export type ZMCPointValue = z.infer<typeof zMCPointValue>;

const zMCSize = z.object({
  id: z.number(),
  name: z.string(),
  pointValues: zMCPointValue.array(),
});
export type ZMCSize = z.infer<typeof zMCSize>;

const zMCPoint = z.object({
  id: z.number(),
  point: zMCPointName,
  description: z.string().nullable(),
  tolPlus: z.number().nullable(),
  tolMinus: z.number().nullable(),
  qc: z.boolean(),
  sizes: zMCSize.array(),
});

const zMCRow = z.object({
  mcPoint: zMCPoint,
});
export type ZMCRow = z.infer<typeof zMCRow>;

const zMCTable = z.object({
  rows: zMCRow.array(),
});
export type ZMCTable = z.infer<typeof zMCTable>;

const zMCSizeColumn = z.object({
  id: z.number(),
  name: z.string(),
});
export type ZMCSizeColumn = z.infer<typeof zMCSizeColumn>;

export const zMeasurementChart = z.object({
  measurementChartEntityDto: zMeasurementChartEntity.nullable(),
  tableDto: zMCTable.nullable(),
  columns: zMCSizeColumn.array().nullable(),
});
export type ZMeasurementChart = z.infer<typeof zMeasurementChart>;
