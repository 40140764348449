import { ZDictRef } from "../componentsDefs";
import { ZAttrComponentView, ZAttrViewInfo } from "../zAttrViewInfo";

const appearanceInitial: ZAttrViewInfo["appearance"] = {
  column: { group: { separator: "newLine" } },
  view: { overflow: "elipsis", separator: "newLine" },
};
const stylesInitial: ZAttrViewInfo["styles"] = {
  fontWeight: "400",
  textAlign: "left",
};

const textAppearanceStylesInintial = {
  appearance: appearanceInitial,
  styles: stylesInitial,
};

export const DictRefInitialComponent: Partial<ZDictRef> = { mode: "standart" };

const viewInitialData: Record<
  ZAttrComponentView["view"],
  Omit<ZAttrViewInfo, "ver">
> = {
  DictRef: {
    component: DictRefInitialComponent as ZDictRef,
    ...textAppearanceStylesInintial,
  },
  ImgList: {
    appearance: appearanceInitial,
  },
  PersonCellInfo: textAppearanceStylesInintial,
  LinkStd: textAppearanceStylesInintial,
  LinkView: textAppearanceStylesInintial,
  ObectRef: textAppearanceStylesInintial,
  SimpleText: textAppearanceStylesInintial,
  ContentLangInfo: textAppearanceStylesInintial,
  Tag: textAppearanceStylesInintial,
  FileList: textAppearanceStylesInintial,
  AttrLinked: textAppearanceStylesInintial,
};

export const getInitialViewData = (name: ZAttrComponentView["view"]) =>
  viewInitialData[name];
