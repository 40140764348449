import z from "zod";

export interface ZChildAttribute {
  attributeId: number;
  name: string;
  linkedAttributes: ZChildAttribute[];
}
export const zChildAttribute: z.ZodType<ZChildAttribute> = z.lazy(() =>
  z.object({
    attributeId: z.number(),
    name: z.string(),
    linkedAttributes: z.array(zChildAttribute),
  }),
);
