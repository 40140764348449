import React, { useEffect } from "react";
import { ObjectServiceExt } from "src/businessServices/businessServises.types";
import { ChildEntities } from "src/components/ChildEntities";
import { LoaderBox } from "src/components/LoaderBox";
import { observer } from "mobx-react-lite";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { techpackControlStore as store } from "./TechpackControlStore";
import styles from "./TechpackControl.module.less";

interface PropsTechpackControl {
  info: ObjectServiceExt;
  entityData: EntityCardDataExt;
}

export const TechpackControl: React.FC<PropsTechpackControl> = observer(
  ({ info: { id }, entityData: { entity, object, typesMap } }) => {
    useEffect(() => {
      store.init(id, object.id, typesMap);
    }, [id]);

    return (
      <div className={styles.container}>
        <LoaderBox
          remoteData={store.settings}
          drawReady={(settings) =>
            settings.techpackObjectId &&
            store.childEntsStore && (
              <ChildEntities
                entityId={entity?.id}
                parentObjId={settings.techpackObjectId}
                editorInfo={store.editorInfo}
                outerStore={store.childEntsStore}
                disableResize
              />
            )
          }
        />
      </div>
    );
  },
);
