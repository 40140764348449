import * as React from "react";
import { Button, Form, FormInstance, Modal, Tag } from "antd";
import { observer } from "mobx-react-lite";
import { TShapeForm } from "src/components/TShapeForm/TShapeForm";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { DeleteOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { EntityAnchor } from "./EntityAnchor";
import { ZEntityAvailableState } from "../EntityPage.types";
import { EdCardValues } from "../apiEntityCard";
import { EntityCardStore, entityCardFormName } from "../EntityCardStore";
import { EntityStates } from "./EntityStates";
import styles from "./EntityFormWithAnchor2.module.less";

interface PropsEntityFormWithAnchor2 {
  title: string;
  store: EntityCardStore;
  submitText: string;
  onSubmit(values: EdCardValues): Promise<EdCardValues>;
  onSuccess(values: EdCardValues): void;
  disabled: boolean;
  availableStates: ZEntityAvailableState[] | undefined;
  onChangeState?(stateId: number | string, form: FormInstance): Promise<void>;
  onDelete?(): Promise<void>;
}

const idEntityFormContainer = "EntityFormContainer";

export const EntityFormWithAnchor2: React.FC<PropsEntityFormWithAnchor2> =
  observer((props) => {
    const {
      title,
      submitText,
      store,
      onSubmit,
      onSuccess,
      disabled,
      onDelete,
      availableStates,
      onChangeState,
    } = props;
    const { rootBlock, formStore, anchors, curStateName } = store;
    const [form] = Form.useForm();
    const buttonsSize: SizeType = "middle";

    if (!rootBlock) return null;

    const extraButtons: React.ReactNode = !!onDelete && (
      <Button
        id="deleteCurEntity"
        icon={<DeleteOutlined />}
        onClick={() => {
          Modal.confirm({
            title: t("Attention!"),
            content: t("Do you want to delete the current entry?"),
            okButtonProps: { danger: true, id: "deleteCurEntityOk" },
            cancelButtonProps: { id: "deleteCurEntityCancel" },
            onOk: () => onDelete(),
          });
        }}
        danger
        size={buttonsSize}
      >
        {t("Delete")}
      </Button>
    );

    const curStateView = curStateName ? (
      <Tag color="gold">{curStateName}</Tag>
    ) : undefined;

    return (
      <TShapeForm
        title={title}
        extraTitle={curStateView}
        form={form}
        formProps={{
          name: entityCardFormName,
          initialData: store.initialData,
          block: rootBlock,
          store: formStore,
          submit: onSubmit,
          submitText,
          onSuccess,
          disabled,
          buttonsSize,
          extraButtons,
          onValuesChange(changedValues, values) {
            store.onFormValuesChange(form, changedValues, values);
          },
        }}
        leftPart={
          <>
            <EntityAnchor
              anchors={anchors}
              containerSelector={`#${idEntityFormContainer}`}
            />
            {curStateView && (
              <EntityStates
                currentState={curStateView}
                availableStates={availableStates}
                onChangeState={async (stateId) => {
                  onChangeState?.(stateId, form);
                }}
              />
            )}
          </>
        }
        leftPartClass={styles.leftPartCustom}
        rightPartProps={{ id: idEntityFormContainer }}
      />
    );
  });
