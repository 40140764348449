// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__drawer--Ygz7c .ant-drawer-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 16px 24px;
}
.src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__drawer--Ygz7c .ant-drawer-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.ant-dropdown-menu-item:has(.src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__button--nbamc) {
  padding: 0 !important;
  background: none !important;
}
.ant-dropdown-menu-item:has(.src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__button--nbamc) .src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__button--nbamc {
  border: none;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  align-items: center;
  padding: 5px 12px;
  width: 100%;
  font-size: inherit;
  height: auto;
}
.ant-dropdown-menu-item:has(.src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__button--nbamc) .src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__button--nbamc:hover:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.04);
  color: inherit !important;
}
.src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__icon--oGWH9 svg {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChildEntities/ChildEntitiesActions/CopyIn/CopyIn.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AALA;EASI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;AADJ;AAKA;EACE,qBAAA;EACA,2BAAA;AAHF;AACA;EAKI,YAAA;EACA,6BAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AAHJ;AAVA;EAiBI,qCAAA;EACA,yBAAA;AAJJ;AAOA;EAEI,eAAA;AANJ","sourcesContent":[".drawer {\n  :global(.ant-drawer-footer) {\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    padding: 16px 24px;\n  }\n\n  :global(.ant-drawer-body) {\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n    height: 100%;\n  }\n}\n\n:global(.ant-dropdown-menu-item):has(.button) {\n  padding: 0 !important;\n  background: none !important;\n\n  .button {\n    border: none;\n    background-color: transparent;\n    box-shadow: none;\n    display: flex;\n    align-items: center;\n    padding: 5px 12px;\n    width: 100%;\n    font-size: inherit;\n    height: auto;\n  }\n\n  .button:hover:not(:disabled) {\n    background-color: rgba(0, 0, 0, 0.04);\n    color: inherit !important;\n  }\n}\n.icon {\n  svg {\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer": `src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__drawer--Ygz7c`,
	"button": `src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__button--nbamc`,
	"icon": `src-components-ChildEntities-ChildEntitiesActions-CopyIn-CopyIn-module__icon--oGWH9`
};
export default ___CSS_LOADER_EXPORT___;
