import { z } from "zod";
import {
  zObectRef,
  zSimpleText,
  zDictRef,
  zImgList,
  zLinkStd,
  zPersonCellInfo,
} from "./componentsDefs";
import { zAttrViewStyles } from "./ZAttrViewStyles";
import { zAttrViewAppearance } from "./ZAttrViewAppearance";
import { zTag } from "./componentsDefs/ZTag";
import { zFileList } from "./componentsDefs/ZFileList";
import { zContentLangInfo } from "./componentsDefs/ZContentLangInfo";
import { zLinkView } from "./componentsDefs/ZLinkView";
import { zAttrLinked } from "./componentsDefs/ZAttrLinked";

export const zAttrComponentView = z.discriminatedUnion("view", [
  zDictRef,
  zObectRef,
  zPersonCellInfo,
  zSimpleText,
  zImgList,
  zLinkStd,
  zLinkView,
  zTag,
  zFileList,
  zContentLangInfo,
  zAttrLinked,
]);
export type ZAttrComponentView = z.infer<typeof zAttrComponentView>;
export type AttrViewCompName = ZAttrComponentView["view"];

export const zAttrViewInfo = z.object({
  ver: z.literal(1),
  appearance: zAttrViewAppearance.optional().nullable(),
  styles: zAttrViewStyles.optional().nullable(),
  component: zAttrComponentView.optional().nullable(),
});
export type ZAttrViewInfo = z.infer<typeof zAttrViewInfo>;
