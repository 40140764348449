import React, { useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { observer } from "mobx-react-lite";
import { ChangeStateBulkButton } from "src/pages/EntityFiltersPage/EntityList/ChangeStateBulkButton/ChangeStateBulkButton";
import { ZChildEntities } from "src/common/attrEdit/components/ZChildEntities";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { ChildEntitiesStore } from "../ChildEntitiesStore";
import styles from "./ChildEntitiesActions.module.less";
import { CopyIn } from "./CopyIn";
import { CopyFrom } from "./CopyFrom";
import { ChildEntsActionType } from "../ChildEntities.types";

interface PropsChildEntitiesActions {
  store: ChildEntitiesStore;
  entityId: number;
  disabled?: boolean;
  editorInfo?: ZChildEntities;
}

export const ChildEntitiesActions: React.FC<PropsChildEntitiesActions> =
  observer(
    ({
      store: { tableStore, objectId: parentObjId, avalibleActionsSet },
      entityId,
      disabled,
      editorInfo,
    }) => {
      if (!parentObjId || !tableStore) return null;
      const [loading, setLoading] = useState<boolean>(false);

      const actionsMap: Partial<Record<ChildEntsActionType, ItemType>> = {
        [ChildEntsActionType.changeState]: {
          key: "stateChange",
          label: (
            <ChangeStateBulkButton
              store={tableStore}
              objectId={Number(parentObjId)}
            />
          ),
        },
        [ChildEntsActionType.copyIn]: {
          key: "copyIn",
          label: (
            <CopyIn
              tableStore={tableStore}
              entityId={entityId}
              onLoad={setLoading}
              parentObjId={parentObjId}
            />
          ),
        },
        [ChildEntsActionType.copyFrom]: {
          key: "copyFrom",
          label: (
            <CopyFrom
              tableStore={tableStore}
              entityId={entityId}
              onLoad={setLoading}
              parentObjId={parentObjId}
              editorInfo={editorInfo}
            />
          ),
        },
      };

      const menuItems = Object.entries(actionsMap)
        .filter(([key]) => avalibleActionsSet.has(key as ChildEntsActionType))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([_key, item]) => [item, { type: "divider" } as ItemType])
        .flat();
      menuItems.pop();
      if (!menuItems.length) return null;
      const menuProps: MenuProps = { items: menuItems };

      return (
        <Dropdown menu={menuProps} placement="bottomRight" disabled={disabled}>
          <Button
            id="entityListActions"
            icon={<MoreOutlined className={styles.icon} />}
            loading={loading}
            className={styles.menuButton}
          />
        </Dropdown>
      );
    },
  );
