import { EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Tag } from "antd";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import TextArea, { TextAreaProps, TextAreaRef } from "antd/es/input/TextArea";
import { MenuProps } from "antd/lib";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";
import {
  isAttrAllowedInFormula,
  makeFormulaAttPartView,
  transformFormula4Show,
} from "src/common/attrEdit/components";
import { onError } from "src/common/onError";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { getIdLabels } from "src/references/getIdNames";
import { makeDictNameById } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";

const createStore = (objectId: number) =>
  makeAutoObservable({
    attrList: [] as ZAttribute[],
    setAttrList(list: ZAttribute[]) {
      this.attrList = list;
    },
    attrTypesDict: {} as Record<number, string>,
    setAttrTypesDict(dict: Record<number, string>) {
      this.attrTypesDict = dict;
    },
    async init() {
      return Promise.all([
        loadObjectAttrinbutesAll(objectId),
        getIdLabels("attrType", "attrType"),
      ])
        .then(([atts, labels]) => {
          this.setAttrList(atts);
          this.setAttrTypesDict(makeDictNameById(labels));
        })
        .catch(onError);
    },
  });

type PropsFormulaTemplateInput = Omit<
  TextAreaProps,
  "name" | "onChange" | "value"
> & {
  objectId: number;
  curAttrId: number;
  name: string[];
  value?: string;
  onChange?: (v: string) => void;
};

export const FormulaTemplateInput2: React.FC<PropsFormulaTemplateInput> =
  observer(({ objectId, curAttrId, name, value, onChange, ...props }) => {
    const store = useMemo(() => createStore(objectId), [objectId]);
    const inputRef = useRef<TextAreaRef>(null);
    const form = useFormInstance();

    const init = async () => {
      await store.init();
      const view = await transformFormula4Show(value || "", store.attrList);
      form.setFields([{ name, touched: false, value: view }]);
    };
    useEffect(() => {
      init();
    }, [store]);

    const isEmpty = store.attrList.length === 0;
    if (isEmpty)
      return <Tag color="red">Нет атрибутов для использования в формуле</Tag>;
    const allowedAtts = store.attrList.filter((attr) =>
      isAttrAllowedInFormula(attr, curAttrId, store.attrTypesDict),
    );
    const items: MenuProps["items"] = allowedAtts.map((attr) => ({
      key: attr.id,
      label: attr.name,
      onClick: () => {
        const { current } = inputRef;
        const position =
          current?.resizableTextArea?.textArea?.selectionStart || 0;
        const safeValue = value || "";
        const newFormula =
          safeValue.slice(0, position) +
          makeFormulaAttPartView(attr) +
          safeValue.slice(position);
        onChange?.(newFormula);
      },
    }));
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <TextArea
          {...props}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          ref={inputRef}
        />
        <Dropdown menu={{ items }}>
          <Button>
            <EditOutlined />
          </Button>
        </Dropdown>
      </Space>
    );
  });
