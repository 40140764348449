import React, { useEffect, useMemo } from "react";
import { ModalVertFixed } from "src/components/ModalVertFixed";
import { Button, Flex, Tree } from "antd";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import { TableStore } from "src/components/tables/TableStore";
import { onError } from "src/common/onError";
import { CopyOutlined } from "@ant-design/icons";
import { EntityCopyButtonStore } from "./EntityCopyButtonStore";
import styles from "./EntityCopyButton.module.less";
import { ZEntityRow, ZEntityFilters } from "../types";

interface PropsEntityCopyButton {
  tableStore: TableStore<ZEntityRow, ZEntityFilters>;
  objectId: number;
  onLoad?: (flag: boolean) => void;
}

export interface ChildAttsTreeItem {
  readonly title: string;
  readonly key: React.Key;
  children?: ChildAttsTreeItem[];
}

export const EntityCopyButton: React.FC<PropsEntityCopyButton> = observer(
  ({ tableStore, objectId, onLoad }) => {
    const store = useMemo(
      () => new EntityCopyButtonStore(objectId, tableStore),
      [],
    );

    const {
      isModalOpen,
      defaultChecked,
      checkedKeys,
      onCheck,
      loading,
      treeData,
      expandedKeys,
      defaultExpanded,
      onExpand,
    } = store;

    useEffect(() => {
      if (isModalOpen) {
        store.setCheckedItems(defaultChecked ?? []);
        store.setExpanded(defaultExpanded ?? []);
      }
    }, [isModalOpen]);

    const onClick = async () => {
      try {
        onLoad?.(true);
        if (!store.treeData) await store.init();
        if (store.treeData?.length) {
          store.setIsModalOpen(true);
        } else {
          store.save();
        }
      } catch (error) {
        onError(error);
      } finally {
        onLoad?.(false);
      }
    };

    return (
      <>
        <Button
          onClick={onClick}
          icon={<CopyOutlined className={styles.icon} />}
          disabled={tableStore.selected.length !== 1}
          className={styles.button}
        >
          {t("Copying an instance")}
        </Button>
        <ModalVertFixed
          open={isModalOpen}
          onCancel={() => store.setIsModalOpen(false)}
          okButtonProps={{ loading }}
          onOk={() =>
            store.save(
              checkedKeys.length
                ? checkedKeys.map((key) => Number(key))
                : undefined,
            )
          }
          okText={t("Copy")}
          title={t("Copying an instance")}
        >
          <Flex vertical gap={8}>
            <div className={styles.subTitle}>
              {t("Select dependent objects to copy")}
            </div>
            <Tree<ChildAttsTreeItem>
              treeData={treeData ?? []}
              checkable
              expandedKeys={expandedKeys}
              onExpand={onExpand}
              selectable={false}
              checkedKeys={checkedKeys}
              checkStrictly
              onCheck={onCheck}
            />
          </Flex>
        </ModalVertFixed>
      </>
    );
  },
);
