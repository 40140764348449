import { makeDictionary } from "src/common/makeDictionary";
import { BusinessServiceDescriptor } from "./BusinessServiseDescriptor";
import { bsRegistration } from "./services/bsRegistration";
import { bsBom } from "./services/bsBom";
import { bsChat } from "./services/bsChat/bsChat";
import { bsMeasurementChart } from "./services/bsMeasurementChart/bsMeasurementChart";
import { bsTechpack } from "./services/bsTechpack";

export const allBusinessServices: BusinessServiceDescriptor[] = [
  bsBom,
  bsRegistration,
  bsChat,
  bsMeasurementChart,
  bsTechpack,
];

export const businessServicesDict = makeDictionary(
  allBusinessServices,
  ({ key }) => key,
);
