import { UploadOutlined } from "@ant-design/icons";
import { Button, notification, Upload, UploadFile } from "antd";
import { UploadProps } from "antd/lib";
import React, { useEffect, useState } from "react";

type PropsRCUploader = Omit<UploadProps, "onChange"> & {
  onChange?: (file: UploadFile | null) => void;
  value?: UploadFile;
  allowedFileTypes: Set<string>;
};

export const RCUploader: React.FC<PropsRCUploader> = ({
  onChange,
  value,
  allowedFileTypes,
  ...props
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (value) {
      setFileList([value]);
    } else {
      setFileList([]);
    }
  }, [value]);

  const uploadCpmProps: UploadProps = {
    onRemove: () => {
      setFileList([]);
      onChange?.(null);
    },
    beforeUpload: (file) => {
      if (!file) return false;

      if (!allowedFileTypes.has(file.type)) {
        notification.error({
          message: "Ошибка загрузки",
          description: "Данный формат файла не поддерживается",
        });
        return Upload.LIST_IGNORE;
      }

      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return (
    <Upload
      {...props}
      {...uploadCpmProps}
      showUploadList={fileList.length > 0 ? { showRemoveIcon: true } : false}
    >
      <Button size="small" icon={<UploadOutlined />}>
        Прикрепить файл
      </Button>
    </Upload>
  );
};
RCUploader.defaultProps = {
  onChange: undefined,
  value: undefined,
};
