import * as React from "react";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { ifDef } from "src/common/ifDef";
import { Empty, Form } from "antd";
import { MChartEntityStore } from "../../MChartEntityStore";
import { mcTable2ed } from "./EdMcTable";
import styles from "./MChartTable.module.less";

interface PropsMChartTable {
  store: MChartEntityStore;
}

const span = (count: number) => `span ${count}`;

const bss = (isBaseSize: boolean | undefined): [boolean, string] => [
  !!isBaseSize,
  styles.baseSizeCell,
];

export const MChartTable: React.FC<PropsMChartTable> = observer(({ store }) => {
  const { columns, templateColumns, rows, mcData } = store;
  const form = Form.useFormInstance();
  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue(mcTable2ed(rows));
  }, [mcData]); // Обновление данных формы должно происходить не всегда, когда меняется rows.
  // Например, rows меняется при добавлении/удалении строк. Но форма не должна меняться.
  // А вот если произошло обновление всех данных, тогда должна.
  return (
    <div className={styles.tableBox}>
      <div
        className={styles.tableHeader}
        style={{ gridTemplateColumns: templateColumns }}
      >
        {/* First header line */}
        {columns
          .filter(({ title }) => !!title)
          .map(
            ({ key, title, rowSpan, colSpan, isBaseSize, noPadTitle }, i) => (
              <div
                key={`h1:${key}`}
                className={classNames([
                  styles.cellHd,
                  styles.cell,
                  styles.cellTop,
                  [!noPadTitle, styles.pad],
                  [i === 0, styles.cellLeft],
                  bss(isBaseSize),
                ])}
                style={{
                  gridRow: ifDef(rowSpan, span),
                  gridColumn: ifDef(colSpan, span),
                }}
              >
                {title}
              </div>
            ),
          )}
        {/* Second header line */}
        {columns
          .filter(({ subTitle }) => !!subTitle)
          .map(({ key, subTitle, isBaseSize }) => (
            <div
              key={`h2:${key}`}
              className={classNames([
                styles.cellHd,
                styles.cell,
                bss(isBaseSize),
              ])}
            >
              {subTitle}
            </div>
          ))}
        {/* Rows */}
        {rows.length === 0 && (
          <div
            className={classNames([styles.cell, styles.cellLeft])}
            style={{ gridColumn: span(columns.length) }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
        {rows.length !== 0 &&
          rows.map((row) => (
            <React.Fragment key={row.mcPoint.id}>
              {columns.map(({ key, cell, align, isBaseSize }, i) => (
                <div
                  key={key}
                  className={classNames([
                    styles.cell,
                    styles.cellRow,
                    [i === 0, styles.cellLeft],
                    [align === "center", styles.cellCentered],
                    bss(isBaseSize),
                  ])}
                >
                  {cell(row)}
                </div>
              ))}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
});
