import { ChildAttsTreeItem } from "../EntityCopyButton";
import { ZChildAttribute } from "../ZChildAttribute";

export const makeChildAttsTree = (
  hierarchy: ZChildAttribute[],
): ChildAttsTreeItem[] =>
  hierarchy.map(({ attributeId, name, linkedAttributes }) => ({
    key: attributeId,
    title: name,
    ...(linkedAttributes.length && {
      children: makeChildAttsTree(linkedAttributes),
    }),
  }));
