// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__commonBox--xlz2u {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__infoRow--dUGd7 {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__toolsRow--x3KIp {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__selectInfo--sJBu4 {
  flex: 1;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__dropDownBox--V0Dal {
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__dropDownBox--V0Dal > div {
  padding: 8px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsMeasurementChart/MChartEntity/EditMCEntity/EditMCEntity.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACA;EACI,OAAA;AACJ;AACA;EACI,aAAA;EACA,sBAAA;AACJ;AAAI;EAAU,iBAAA;AAGd","sourcesContent":[".commonBox {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n.infoRow {\n    display: flex;\n    flex-direction: row;\n    gap: 50px;\n}\n.toolsRow {\n    display: flex;\n    flex-direction: row;\n    gap: 16px;\n}\n.selectInfo {\n    flex: 1;\n}\n.dropDownBox {\n    display: flex;\n    flex-direction: column;\n    & > div { padding: 8px 16px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commonBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__commonBox--xlz2u`,
	"infoRow": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__infoRow--dUGd7`,
	"toolsRow": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__toolsRow--x3KIp`,
	"selectInfo": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__selectInfo--sJBu4`,
	"dropDownBox": `src-businessServices-services-bsMeasurementChart-MChartEntity-EditMCEntity-EditMCEntity-module__dropDownBox--V0Dal`
};
export default ___CSS_LOADER_EXPORT___;
