import * as React from "react";
import styles from "./MCInfoItem.module.less";

interface PropsMCInfoItem {
  label: string;
  value: string | undefined;
}

export const MCInfoItem: React.FC<PropsMCInfoItem> = ({
  label,
  value = "",
}) => (
  <div className={styles.infoItem}>
    {label}: <span>{value}</span>
  </div>
);
