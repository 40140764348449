import { transformFormula4Save } from "src/common/attrEdit/components";
import { EdAttribute } from "../EdAttribute";
import { loadObjectAttrinbutesAll } from "../../objectsApi";

export const transformEdAttVals4Save = async (
  values: EdAttribute,
  objectId: number,
) => {
  const newVals = values;
  switch (newVals.editorInfo?.component?.editor) {
    case "Formula": {
      const attrList = await loadObjectAttrinbutesAll(objectId);
      newVals.editorInfo.component.formula = await transformFormula4Save(
        newVals.editorInfo.component.formula,
        attrList,
      );
      break;
    }
    default:
      break;
  }
  return newVals;
};
