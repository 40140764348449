import { t } from "i18next";
import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { loadObject } from "src/pages/EntityCardPage/apiEntityCard";
import { EntityCardStore } from "src/pages/EntityCardPage/EntityCardStore";
import { compoundEntityTableStore } from "src/pages/EntityFiltersPage/EntityList/compoundEntityTableStore";
import {
  ZEntityFilters,
  ZEntityRow,
} from "src/pages/EntityFiltersPage/EntityList/types";
import { ZEntity } from "src/types/ZEntity";
import { TableStore } from "../tables/TableStore";
import { loadChildEntsSummary } from "./loadChildEntsSummary";
import { createTableParamsKey } from "./createTableParamsKey";
import { ChildEntsActionType } from "./ChildEntities.types";

export type ZEntityFiltersChild = ZEntityFilters & {
  parentEntityId?: number;
  parentLinkAttributeId?: number;
};

export class ChildEntitiesStore {
  constructor(
    actions?: Set<ChildEntsActionType>,
    private fnCreate?: (entity: ZEntity) => Promise<ZEntity>,
  ) {
    this.availableActions =
      actions ??
      new Set([
        ChildEntsActionType.changeState,
        ChildEntsActionType.copyIn,
        ChildEntsActionType.copyFrom,
        ChildEntsActionType.create,
        ChildEntsActionType.select,
        ChildEntsActionType.rowClick,
      ]);
    makeAutoObservable(this);
  }

  protected availableActions: Set<ChildEntsActionType>;

  objectId: number = 0;

  tableStore: TableStore<ZEntityRow, ZEntityFiltersChild> | null = null;

  async init(
    objectId: number,
    parentEntityId: number,
    parentLinkAttributeId: number,
    summaryAttrs?: string[],
  ) {
    this.objectId = objectId;
    const objectName = (await loadObject(objectId, { translate: true })).name;
    this.setCurrObjName(objectName);
    this.setSummaryAttrs(summaryAttrs ?? null);
    this.tableStore = await compoundEntityTableStore<
      ZEntityRow,
      ZEntityFiltersChild
    >(
      objectId,
      "id",
      {
        filters: { objectId, parentEntityId, parentLinkAttributeId },
      },
      { keepSelected: true, selectionType: "checkbox" },
      summaryAttrs?.length ? () => this.loadSummary() : undefined,
    );
  }

  get avalibleActionsSet() {
    return this.availableActions;
  }

  get isPopup(): boolean {
    return !!this.subCardStore;
  }

  subCardStore: EntityCardStore | null = null;

  setSubCardStore(store: EntityCardStore | null) {
    this.subCardStore = store;
  }

  submitText: string = "";

  setSubmitText(text: string) {
    this.submitText = text;
  }

  openCreation(parentAttr: string, parentValue: number) {
    this.setSubmitText(t("Add"));
    const store = new EntityCardStore(this.fnCreate);
    this.setSubCardStore(store);
    store.initNew(this.objectId, { [parentAttr]: [String(parentValue)] });
  }

  openEdit(entityId: number) {
    this.setSubmitText(t("Apply"));
    const store = new EntityCardStore();
    this.setSubCardStore(store);
    store.init(String(entityId));
  }

  closeCreation() {
    this.setSubCardStore(null);
  }

  onUpdate() {
    this.tableStore?.reload().catch(onError);
    this.closeCreation();
  }

  currObjName: string = t("Add");

  setCurrObjName(name: string) {
    this.currObjName = name;
  }

  // Итоги вычислений в таблице

  summaryAttrs: string[] | null = null;

  setSummaryAttrs(ids: string[] | null) {
    this.summaryAttrs = ids;
  }

  summaryValues: Record<string, number> | null = null;

  setSummaryValues(values: Record<string, number>) {
    this.summaryValues = values;
  }

  lastTableParams: string = "";

  setLastTableParams(params: string) {
    this.lastTableParams = params;
  }

  async loadSummary() {
    const { summaryAttrs, tableStore, lastTableParams } = this;
    if (!tableStore || !tableStore.filters) return;

    const { filters: curFilters, totalItems: curTotal } = tableStore;
    const { objectId, parentEntityId, parentLinkAttributeId, query } =
      curFilters;
    const curTableParams = createTableParamsKey(curFilters, curTotal);

    if (
      !summaryAttrs?.length ||
      curTableParams === lastTableParams ||
      !objectId ||
      !parentEntityId ||
      !parentLinkAttributeId
    )
      return;

    const totalValues = (
      await loadChildEntsSummary(
        objectId,
        parentEntityId,
        parentLinkAttributeId,
        summaryAttrs,
        query,
      )
    ).reduce(
      (result, { attributeId, sum }) => ({ ...result, [attributeId]: sum }),
      {},
    );

    this.setSummaryValues(totalValues);
    this.setLastTableParams(curTableParams);
  }
}
