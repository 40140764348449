import * as React from "react";
import { Button, Form, FormInstance } from "antd";
import { t } from "i18next";
import { FieldData, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { FormWithBlockStore } from "src/components/FormWithBlocks/FormWithBlockStore";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { observer } from "mobx-react-lite";
import { FormBlockDef } from "./FormWithBlocks.types";
import { packNamePath } from "./items/nameUtils";
import { onErrorFinish } from "./onErrorFinish";
import styles from "./FormWithBlock.module.less";

// Это заимствовано из внутренностей ant, т.к. данный тип не экспортируется.
// Но при неявном приведении бывают ошибки компиляции.
type RecursivePartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
          ? RecursivePartial<T[P]>
          : T[P];
    }
  : // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    any;

export interface PropsFormWithBlocks<TData> {
  name: string;
  block: FormBlockDef;
  // Если submitText undefined, то используется дефолтный текст. Если null или пустая строка, то кнопка не выводится
  submitText?: string | null;
  store: FormWithBlockStore;
  onValuesChange?(changedValues: Partial<TData>, values: TData): void;
  submit(values: TData): Promise<TData>;
  onSuccess?(values: TData): void;
  initialData?: RecursivePartial<TData>;
  extraButtons?: React.ReactNode;
  disabled?: boolean;
  form?: FormInstance<TData>;
  buttonsSize?: SizeType;
  formSize?: SizeType;
  prefixContent?: React.ReactNode;
}

export const FormWithBlocks = observer(
  <TData extends {}>(props: PropsFormWithBlocks<TData>): React.ReactElement => {
    const {
      name,
      block,
      submitText,
      store,
      onValuesChange,
      submit,
      onSuccess,
      initialData,
      extraButtons,
      disabled,
      form,
      buttonsSize = "middle",
      formSize = "middle",
      prefixContent,
    } = props;
    const formInstance: FormInstance<TData> = form || Form.useForm<TData>()[0];
    React.useEffect(() => {
      formInstance.resetFields();
      if (initialData) {
        formInstance.setFieldsValue(initialData);
        if (onValuesChange) {
          const currentValues = formInstance.getFieldsValue();
          onValuesChange(initialData, currentValues);
        }
      }
      store.setExternalErrors({});
    }, [initialData]);

    const onFinishFailed = (info: ValidateErrorEntity) => {
      const error = info.errorFields[0];
      if (error) {
        store.activate(name, error.name, block);
      }
    };
    const onFinish = async (formData: TData) => {
      try {
        store.setExternalErrors({});
        store.setSaving(true);
        const result = await submit(formData);
        onSuccess?.(result);
      } catch (e) {
        onErrorFinish(e, store, name, block);
      } finally {
        store.setSaving(false);
      }
    };

    const buttonsBlock: React.ReactElement[] = [];
    const finalSubmitText = submitText === undefined ? t("Submit") : submitText;
    if (!disabled && finalSubmitText) {
      buttonsBlock.push(
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={store.saving}
          form={name}
          onClick={() => formInstance.submit()}
          size={buttonsSize}
        >
          {finalSubmitText}
        </Button>,
      );
    }
    if (extraButtons) {
      buttonsBlock.push(
        <React.Fragment key="extra">{extraButtons}</React.Fragment>,
      );
    }

    const onFieldsChange = (changed: FieldData[]) => {
      changed.forEach((fd) => {
        store.clearError(packNamePath(fd.name));
      });
    };

    return (
      <div className={styles.layout}>
        <Form<TData>
          component="div"
          form={formInstance}
          name={name}
          size={formSize}
          layout="vertical"
          className={styles.form}
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          onFieldsChange={onFieldsChange}
          disabled={disabled}
          initialValues={initialData}
        >
          {prefixContent}
          <div className={styles.blocksBox}>
            {block.render("", { store, prevName: [] })}
          </div>
        </Form>
        {buttonsBlock.length > 0 && (
          <div className={styles.buttons}>{buttonsBlock}</div>
        )}
      </div>
    );
  },
);
